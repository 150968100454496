/**
 * Scout Client Service
 */

import { cache, cached, call, EP, fill, find, post, sort } from "./API";
import { ScClient } from "./Types";

/** API */

export const getClients = async (): Promise<ScClient[]>  => {
    const data = await call("GET", EP.getClients, "clients");
    if(!data)return [];
    let clients = data.clients;
    clients = sort(clients, "name");

    return clients;
}

export const updateClient = async (clientId:string, propsToUpdate:any): Promise<any>  => {
  const data = await post(fill(EP.updateClientBy["clientId"], [["clientId", clientId]]), propsToUpdate);
  if(!data)return [];
  return data;
}



/** Static */
export const findClientBy = async (property:string, value:string): Promise<ScClient> => {
  return find((await getClients()), property, value);
}



/** Utils */


import Button, { ButtonStyle } from "../../inputs/button/Button";
import "./Notice.scss";


interface IProps {
  title: string;
  message: string;
  actionText?: string;
  actionLink?: string;
}

export default function Notice(props:IProps) {
    const {title, message, actionText, actionLink} = props;
    return (
        <div className={"notice "}>
            <h3>{title}</h3>
            <div>{message}</div>
            {actionText && actionLink && (<Button style={ButtonStyle.primary} text={actionText} to={actionLink}/>)}
        </div>
    );
}

import { ReactComponent as IconMonitor } from '~/assets/icons/monitor-ico.svg';
import ToggleGroup from "~/app/components/inputs/toggleGroup/ToggleGroup";
import FeatureManager from "~/app/services/FeatureManager";
import { ScPage, Team, TimeFrequency, TimeType } from "~/app/services/Types"
import Util from "~/app/services/Util";
import BasePage, { BasePageState } from "../BasePage";
import { WidgetStyles } from '~/app/components/core/widget/Widget';


interface IProps extends ScPage{

}


interface IState extends BasePageState{
    
}

export default class PagePerformance extends BasePage<IProps, IState>{

    protected dontUpdate:boolean = false;

    constructor(props:IProps){
        super(props);
       
        this.state = {
            loading:true,
            timeFrequency: TimeFrequency.Monthly,
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const {page, appContext} = pageContext;

        const widgets = [
            { slug:"timeUsage", widgetStyle: WidgetStyles.Normal, data:{type:"chart", team:Team.Both}},
            { slug:"git", data:{type:"chart", timeType:TimeType.Teams}},
            { slug:"userList", size:6, data:{team:Team.Red}  },
            { slug:"userList", size:6, data:{team:Team.Blue} },
        ];

        const filteredWidgets: any = widgets.map(widget => {
            const widgetPermission = FeatureManager.userHasFeature(appContext.user, this.props.slug+":page:widget_"+widget.slug);
            if(!widgetPermission)return;
            return widget;
        });       
        
        return {
            title: "Performance",

            icon: (<IconMonitor />),
            className: "", 
            showTop: true,
            renderCustom: false,
            columns:2,
            widgets: filteredWidgets
        }
    }

    public onRenderTop() {
        
        return (
            <ToggleGroup
                options={Util.getTimeFrequencyAsArray()}
                defaultValue={TimeFrequency.Monthly}
                onOptionChange={(key: any, text: string) => {
                    this.setState({ timeFrequency:key });
                }}
            />
        )
    }


}

import { useAuth0 } from "@auth0/auth0-react";


interface IProps {
 
}


export default function LoginButton(props:IProps) {
  const { loginWithRedirect } = useAuth0();

  return <button className={"loginButton"} onClick={() => loginWithRedirect()}>Log In</button>;
};


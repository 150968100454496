import { useState } from 'react';
import './Accordion.scss';


interface IProps {
    title: string;
    onClick?:() => boolean | void;
    children?: any;
}

export default function Accordion(props:IProps) {
    
    const [open, setOpen] = useState(false);
    const {onClick, title, children} = props;
    
    
    return <div className="Accordion">
        <div className="accordionTitle" onClick={() => {
            if(onClick && !onClick())return ;
            setOpen(!open)
        }}>{title}</div>
        {open && (<div className="accordionContent">{children}</div>)}
    </div>


}
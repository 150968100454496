
import './Count.scss';
import CountUp from 'react-countup';

interface IProps {
  count: number;
}

export default function Count(props: IProps) {
  const {count} = props;
  
  const countVar:any = {
    "--num": count
  };
  return (
    <div className='count' style={countVar}>
      {/* {count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
      <CountUp 
        end={count} 
        separator=","
        duration={2}
        enableScrollSpy={true}
      />

    </div>
  );
}

import { ReactComponent as IconMonitor } from '~/assets/icons/monitor-ico.svg';
import ToggleGroup from "~/app/components/inputs/toggleGroup/ToggleGroup";
import FeatureManager from "~/app/services/FeatureManager";
import { IWidgetContext, ScPage, ScUser, Team, TimeFrequency, TimeType } from "~/app/services/Types"
import Util from "~/app/services/Util";
import BasePage, { BasePageState } from "../BasePage";
import WidgetManager from '~/app/services/WidgetManager';
import { WidgetStyles } from '~/app/components/core/widget/Widget';
import { getUserBy } from '~/app/services/UserService';


interface IProps extends ScPage{

}


interface IState extends BasePageState{
    user: ScUser;
}

export default class PageUserProfile extends BasePage<IProps, IState>{

    constructor(props:IProps){
        super(props);
       
        this.state = {
            loading:true,
            timeFrequency: TimeFrequency.Monthly,
            user: null
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const { appContext} = pageContext;
        const {user} = this.state;

        const widgets = [
            { slug:"timeUsage", data:{type:"chart", timeType:TimeType.User, user:user}},
            { slug:"gitCommits", data:{timeType: TimeType.User, user:user}},
            { slug:"grid", size:12, data:{columns: 3, widgets: [
                { slug:"count",  widgetStyle: WidgetStyles.Orange, data:{title: "Commits", count:178} },
                { slug:"count", widgetStyle: WidgetStyles.LightRed, data:{title: "Lines of code", count:79102} },
                { slug:"count", widgetStyle: WidgetStyles.LightBlue, data:{title: "Repos", count:4} },
            ]}},
            { slug:"recentActivity", size:12, data:{user: user} },
        ];

        const filteredWidgets: any = widgets.map(widget => {
            const widgetPermission = FeatureManager.userHasFeature(appContext.user, this.props.slug+":page:widget_"+widget.slug);
            if(!widgetPermission)return;
            return widget;
        });       
        
        return {
            title: "Performance",

            icon: (<IconMonitor />),
            className: "", 
            showTop: false,
            renderCustom: true,
            columns:2,
            widgets: filteredWidgets
        }
    }

    public async onPostLoad() {
        const pageContext = this.props.context;
        const {appContext} = pageContext;

        let userEmail = appContext.searchParams.get('email');

        if (userEmail) {
            const user = await getUserBy("email", userEmail);
            this.setState({user: user});
        }
    }

    public render(): React.ReactElement<IProps> {
         const pageContext = this.props.context;
        const {page, appContext} = pageContext;
        const {slug} = this.props;
        const {icon, title, showTop, renderCustom} = this.info();
        const {loading} = this.state;
        
        return (
            <div className="page" data-page={slug}>
                {this.renderUserBanner()}

                <main>
                    <div className="topArea">
                        <h3>
                            {icon} {title}
                        </h3>
                        {this.onRenderTop()}
                    </div>

                    {this.onRenderGrid()}

                </main>
            </div>
        );
    }

    public renderUserBanner() {
        const pageContext = this.props.context;
        const {page, appContext} = pageContext;
        const {timeFrequency, user} = this.state;
        
        const widgetContext:IWidgetContext = {
            appContext: appContext,
            pageContext: pageContext,

            page: pageContext.page,
            client: pageContext.client,
            project: pageContext.project,
            timeFrequency: timeFrequency,
            size: 12,
            match: false,
            widgetStyle: WidgetStyles.Normal,
            data: {user: user},
        }

        return WidgetManager.buildWebpart("userBanner", widgetContext);
    }

    public onRenderTop() {
        
        return (
            <ToggleGroup
                options={Util.getTimeFrequencyAsArray()}
                defaultValue={TimeFrequency.Monthly}
                onOptionChange={(key: any, text: string) => {
                    this.setState({ timeFrequency:key });
                }}
            />
        )
    }


}

import ToggleGroup, { IToggleStyle } from "~/app/components/inputs/toggleGroup/ToggleGroup";
import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { ScActivity, ActivitySource, ActivityType, EActivitySource, ETeamFull, ScCost, ScTime, ScWidget, Team, TimeType } from "~/app/services/Types";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import RecentActivity from "./components/RecentActivity";
import { filterLatestActivities, getActivities, getActivitiesByUser } from "~/app/services/ActivityService";
import Notice from "../../core/notice/Notice";

interface IProps extends ScWidget{

}


interface IState extends BaseWidgetState{
    filter: ActivitySource;
    activities: ScActivity[];
}

export default class RecentActivityWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            filter:ActivitySource.Any,
            activities: []
        }
    }

    public info(){
        return   {
            title: "Recent Activity",
            className: "RecentActivity",
            defaultStyle: WidgetStyles.Normal,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    public async loadAsync(context: ScWidgetContext) {
        const {project, data} = context;
        const {user} = data;
        if(!project)return;
        const activities:ScActivity[] = user ? await getActivitiesByUser(project, user) : await getActivities(project);

        this.setState({
            loading:false,
            activities: activities
        })
    }

    public onRenderTop(context: ScWidgetContext) {

        return (
            <ToggleGroup
                options={EActivitySource()}
                defaultValue={ActivitySource.Any}
                style={IToggleStyle.Tabs}
                color={"var(--neutral-black)"}
                width={-1}
                gap={20}
                onOptionChange={(key: any, text: string) => {
                    this.dontUpdate = true;
                    this.setState({filter:key});
                }}
            />
        );
    }

    public onRender(context: ScWidgetContext) {
        const {filter, activities} = this.state;
        const project = this.props.context.project;

        if(!activities){
          return (
            <Notice
              title={"Requires a BitBucket connection"}
              message={"To see the latest activity of this project please connect with your BitBucket account."}
              actionLink={`/config/project?id=${project.projectId}`}
              actionText={"Setup BitBucket"}
            />
          )
        }
        if(activities.length === 0){
          return (
            <Notice
              title={"No Activities"}
              message={"No activities for this project yet."}
            />
          )
        }


        return (
            <RecentActivity filter={filter} activities={activities}/>
        )
    }

}

/**
 * Scout Time Service
 */

import moment from "moment";
import { cache, cached, call, EP, fill, sort } from "./API";
import { getProject, getProjectsBy } from "./ProjectService";
import { ScClient, ScJob, ScTime, ScUser, ScUserTime, TimeFrequency } from "./Types";
import Util from "./Util";

/** API */

export const getTimesBy = async (by: "projectId", identity:string, frequency: TimeFrequency, max= 5): Promise<ScTime[]>  => {
  const project = await getProject(identity);
  if(!project.streamTimeId)return null;

  if(frequency === TimeFrequency.Monthly){
  return await getTimesByMonthly(by, identity);
  }else if(frequency === TimeFrequency.Weekly){
      return getTimesByWeekly(by, identity, max)
  }else if(frequency === TimeFrequency.Today){
      return getTimesByToday(by, identity)
  }

  return [];
}



export const getTimesByMonthly = async (by: "projectId", identity:string): Promise<ScTime[]>  => {
    const data = await call("GET", fill(EP.getTimesBy[by], [[by, identity]]), `times_by_${by}_${identity}_monthly`);
    if(!data)return [];
    let times = data.times;

    times = sort(times, "startDate");

    return times;
}

/** TODO SAVE THIS INFO IN SCOUT -- cache this*/
export const getTimesByWeekly = async (by: "projectId", identity:string, weeks:number): Promise<ScTime[]>  => {
    let times: ScTime[] = [];

    if(by == "projectId"){
        let project = await getProject(identity);
		let stJobId = project.streamTimeId;

		const todayDate =  moment().format('YYYY-MM-DD')
		const startDate = moment().subtract(weeks,'w').format('YYYY-MM-DD');

		const data = await call("GET", "st/time/projects?jobId="+stJobId+"&startDate="+startDate+"&endDate="+todayDate);
		if(!data)return [];
		times = data.stProjectTimes;
		times = convertTimes(identity, times, "week");
    times = groupTimesByWeeks(times);

    }

    return times;


}

/** TODO SAVE THIS INFO IN SCOUT  -- cache this*/
export const getTimesByToday = async (by: "projectId", identity:string): Promise<ScTime[]>  => {
    let times: ScTime[] = [];

    if(by == "projectId"){
        let project = await getProject(identity);
		let stJobId = project.streamTimeId;

		const todayDate =  moment().format('YYYY-MM-DD')
		const startDate = moment().startOf('day').format('YYYY-MM-DD');


		const data = await call("GET", "st/time/projects?jobId="+stJobId+"&startDate="+startDate+"&endDate="+todayDate);
		if(!data)return [];

		times = data.stProjectTimes;

		times = convertTimes(identity, times, "hour");
        times = groupTimesByHours(times);


    }

    return times;
}


/** TODO rename this  -- cache this -- dont do by ST ids*/

export const getTimesByGroupUsers = async (by: "projectId", identity:string, frequency:TimeFrequency): Promise<ScTime[]>  => {
    let times: ScTime[] = [];

    if(by === "projectId"){
        let project = await getProject(identity);
		let stJobId = project.streamTimeId;
		let {startDate, endDate} = datesFromFrequency(frequency);

        const data = await call("GET", "st/time/projects/users?jobId="+stJobId+"&startDate="+startDate+"&endDate="+endDate, `st_times_${by}_${identity}_${startDate}_${endDate}`);
        if(!data)return [];
        times = data.stUserTimes;
        times = convertTimes(identity, times, "user");

    }

    return times;
}

export const getTimesByUser = async (by: "projectId", identity:string, frequency:TimeFrequency, user:ScUser): Promise<ScTime[]>  => {
    let times: ScTime[] = [];
    if(!user)return [];
    if(by === "projectId"){
        let project = await getProject(identity);
        let stJobId = project.streamTimeId;
        let {startDate, endDate} = datesFromFrequency(frequency);
        let stUserId = user.streamtime_id;

        const data = await call("GET", "st/time/projects/users?jobId="+stJobId+"&startDate="+startDate+"&endDate="+endDate+"&userId="+stUserId, `st_times_${by}_${identity}_${startDate}_${endDate}_${stUserId}`);
        if(!data)return [];
        times = data.stUserTimes;
        if(!times) return null;
        times = sort(times, "completedDatetime");
        times = convertTimes(identity, times, "user");

    }

    return times;
}

export const getTimesByMultiUser = async (by: "projectId", identity:string, frequency:TimeFrequency, users:ScUser[]): Promise<ScUserTime[]>  => {
    let times: ScUserTime[] = [];
    let userArray:ScUser[] = [...users];

    userArray.map(async (user:ScUser) => {
      if(!user)return;
        let userTimes:ScTime[] = await getTimesByUser(by, identity, frequency, user);
        if(!userTimes)return;
        times.push({
            userEmail: user.email,
            userStId: user.streamtime_id,
            times: userTimes
        });
    })

    return times;
}


/** Static */






/** Utils */
export const datesFromFrequency = (frequency: TimeFrequency) => {
    const {today, weekStart, weekEnd, monthStart, monthEnd} = usefulDates();
    let startDate:string, endDate:string;
    if(frequency == TimeFrequency.Monthly){
        startDate = monthStart;
        endDate = monthEnd;
    }else if(frequency == TimeFrequency.Weekly){
        startDate = weekStart;
        endDate = weekEnd;
    }else if(frequency == TimeFrequency.Today){
        startDate = today;
        endDate = today;
    }

    return {startDate, endDate};
}

export const usefulDates = () => {
    let today = new Date();
    let monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let monthFinish = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let weekStart = moment().startOf("week").format("YYYY-MM-DD");
    let weekEnd = moment().format("YYYY-MM-DD");

    return {
        today: moment(today).format("YYYY-MM-DD"),
        weekStart: moment(weekStart).format("YYYY-MM-DD"),
        weekEnd: moment(weekEnd).format("YYYY-MM-DD"),
        monthStart: moment(monthStart).format("YYYY-MM-DD"),
        monthEnd: moment(monthFinish).format("YYYY-MM-DD"),
    }
}


export const convertTime = (projectId: any, stTime: any, type:string): ScTime => {
    const cost = Number(stTime.cost ? stTime.cost : stTime.totalExTax ? stTime.totalExTax : 0);
    return {
        timeId: projectId+"_"+stTime.date,
        cost: cost,
        totalExTax: cost,
        loggedTime: stTime.completedDatetime,
        startDate: stTime.date,
        endDate: stTime.date,
        minutes: Number(stTime.minutes || 0),
        projectId: projectId,
        team: "red",
        type: type,
    };
}

export const convertTimes = (projectId: any, times: any, type:string): ScTime[] => {
    return times && times.map((stTime: any) => {
        return convertTime(projectId, stTime, type);
    });
}

export const costOfTime = (time:ScTime) => {
    return time.cost ? time.cost : time.totalExTax ? time.totalExTax : 0;
}

export const getCostsByTimes = (times: ScTime[], max: number, frequency: TimeFrequency) => {
    let data: any = [];
    let timesForChart:ScTime[] = [...times];

    if (timesForChart.length) {
      timesForChart = timesForChart.splice(-max);
    }

    timesForChart.reverse();

    data = timesForChart.map((time, index) => {
      if (index > max) return time;
      let label = 'NA';
      if (frequency == TimeFrequency.Monthly) {
        label = Util.getMonthFromDate(time.startDate, true);
      } else if (frequency == TimeFrequency.Weekly) {
        label = Util.getWeeksAgo(time.startDate);
      } else if (frequency == TimeFrequency.Today) {
        label = moment(time.loggedTime).format('h:00 A');
      }
      return {
        //TODO change entries to people
        people: time.entries ? time.entries : 0,
        label: label,
        cost: Math.round(time.cost)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      };
    });

    return data;
  }

export const groupTimesByWeeks = (times: ScTime[]): ScTime[] => {
    let weeks:any = [];
    let weekTimes:ScTime[] = [];

    times.map((time:ScTime) => {
        const weekStart = moment(time.startDate).clone().startOf('week').format("YYYY-MM-DD");
        const weekIndex = weeks.indexOf(weekStart);
        if(weekIndex != -1){

            const week = weekTimes[weekIndex];
            week.cost += costOfTime(time);
            week.totalExTax = week.cost;
            week.minutes += time.minutes ? time.minutes : 0;
            week.entries += 1;
            weekTimes[weekIndex] = week;

        }else{

            weeks.push(weekStart);
            weekTimes.push({
                startDate: weekStart,
                cost: costOfTime(time),
                minutes:time.minutes ? time.minutes : 0,
                completedDatetime: moment(time.startDate).clone().endOf('week').format("YYYY-MM-DD 00:00:00"),
                entries: 1,
                timeId: time.timeId,
                totalExTax: time.totalExTax,
                loggedTime: time.loggedTime,
                endDate: time.endDate,
                projectId: time.projectId,
                team: time.team,
                type: "week",
            });
        }
    });

    return weekTimes;
}

export const groupTimesByHours = (times: ScTime[]):ScTime[] => {
    let hours:any = [];
    let hourTimes:ScTime[] = [];

    times.map((time:ScTime) =>{
        const hour = moment(time.completedDatetime).clone().format("HH");
        const hourIndex = hours.indexOf(hour);
        if(hourIndex != -1){

            const hour = hourTimes[hourIndex];
            hour.cost += costOfTime(time);
            hour.totalExTax = hour.cost;
            hour.minutes += time.minutes ? time.minutes : 0;
            hour.entries += 1;
            hours[hourIndex] = hour;

        }else{

            hours.push(hour);
            hourTimes.push({
                startDate: moment(time.startDate).clone().format("YYYY-MM-DD"),
                cost: costOfTime(time),
                minutes: time.minutes ? time.minutes : 0,
                completedDatetime: moment(time.completedDatetime).clone().format("YYYY-MM-DD HH:MM:SS"),
                entries: 1,
                timeId: time.timeId,
                totalExTax: time.totalExTax,
                loggedTime: time.loggedTime,
                endDate: time.endDate,
                projectId: time.projectId,
                team: time.team,
                type: "hours",
            });
        }
    });

    hourTimes = sort(hourTimes, "completedDatetime");

    return hourTimes;
}

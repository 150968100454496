import { ReactComponent as IconMonitor } from '~/assets/icons/monitor-ico.svg';
import { ReactComponent as IconBack } from '~/assets/icons/arrowleft-ico.svg';
import { ReactComponent as IconSave } from '~/assets/icons/check-ico.svg';
import { ReactComponent as IconDelete } from '~/assets/icons/delete-ico.svg';
import { BbUser, ScClient, ScPage, ScUser, TimeFrequency } from '~/app/services/Types';
import BasePage, { BasePageState } from '../../BasePage';
import { getUserBy, updateUser } from '~/app/services/UserService';
import { getEHUsers, getSTUsers } from '~/app/services/StreamTimeService';
import { FieldType, Grid, SettingField, Widget, WidgetStyles } from '~/app/components/core';
import Button, { ButtonStyle } from '~/app/components/inputs/button/Button';
import { notify, notifyError, notifySuccess } from '~/app/services/ToastService';
import { urlQuery } from '~/app/services/API';
import { getAuthUsers } from '~/app/services/AuthService';
import { getBBUsers } from '~/app/services/BitBucketService';
import {
  AuthOUserSelecter,
  BitBucketCompanies,
  BitBucketUserSelecter,
  EmployeeHeroUserSelecter,
  StreamTimeClients,
  StreamTimeUserSelecter
} from '~/app/components/core/settingField/SettingField';
import { findClientBy, updateClient } from '~/app/services/ClientService';

interface IProps extends ScPage {}

interface IState extends BasePageState {
  client: ScClient;
  updatedClient: any;
}

export default class PageClientEdit extends BasePage<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: true,
      client: null,
      updatedClient: {},
      timeFrequency: TimeFrequency.Monthly
    };
  }

  public info(): any {
    const pageContext = this.props.context;
    const { appContext } = pageContext;

    return {
      title: 'Edit Client',

      icon: <IconMonitor />,
      className: '',
      showTop: false,
      renderCustom: true,
      columns: 2
    };
  }

  public async onPostLoad() {
    const pageContext = this.props.context;
    const { appContext } = pageContext;
    let searchParams = appContext.searchParams;
    if (!searchParams || !searchParams.get('id')) {
      searchParams = urlQuery();
    }

    let clientId = searchParams.get('id');

    if (clientId) {
      const client = await findClientBy('clientId', clientId);
      this.setState({
        loading: false,
        client: client
      });
    } else {
      //Not found
      notifyError(`Client not found '${clientId}'`, appContext.searchParams);
    }
  }

  public render(): React.ReactElement<IProps> {
    const pageContext = this.props.context;
    const { page, appContext } = pageContext;
    const { slug } = this.props;
    const { icon, title, showTop, renderCustom } = this.info();
    const { loading, client } = this.state;

    return (
      <div className="page" data-page={slug}>
        <main>
          <div className="topArea">
            <h3>
              {icon} {title}
            </h3>
            {this.onRenderTop()}
          </div>
          <Grid gap={20} columns={1} key={`${client && client.clientId}_${loading}`}>
            <Widget
              key={'userGeneral'}
              data-size={6}
              widgetStyle={WidgetStyles.Settings}
              title={'General'}
              className={''}
              topLayout={''}
              top={null}
              fit={false}
              loading={loading}
            >
              {client && this.getGeneral(client)}
            </Widget>
          </Grid>
        </main>
      </div>
    );
  }

  public onRenderTop() {
    return (
      <div style={{ gap: 20, display: 'flex', flexWrap: 'wrap', marginRight: '20px' }}>
        <Button
          text="Go back"
          style={ButtonStyle.normal}
          to={'/config'}
          icon={<IconBack />}
          onClick={() => {
            notify('User changes discarded.');
          }}
        />
        <Button
          text="Save Client"
          style={ButtonStyle.primary}
          icon={<IconSave />}
          onClick={() => {
            this.save();
          }}
        />
        <Button
          text="Delete Client"
          style={ButtonStyle.danger}
          icon={<IconDelete />}
          onClick={() => {
            notifyError('User deleted.');
          }}
        />
      </div>
    );
  }

  public save() {
    const { updatedClient, client } = this.state;
    if (!client) return;
    updatedClient['clientId'] = client.clientId;
    updateClient(client.clientId, updatedClient);
    notifySuccess('Client saved!');
    this.setState({ updatedClient: {} });
  }

  public getGeneral(client: ScClient): any {
    return (
      <>
        <SettingField
          slug={'Id'}
          title={'ID'}
          readOnly
          value={client.clientId}
          type={FieldType.Text}
        />
        <SettingField
          slug={'name'}
          title={'Client Name'}
          value={client.name}
          type={FieldType.Text}
          onChange={(slug: string, ref: any, oldVal: any, newVal: any) => {
            this.onFieldChange(slug, newVal);
          }}
        />
        <StreamTimeClients
             slug={"streamtime_id"}
             value={client.streamTimeId}
             onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
        />

        {/* <BitBucketCompanies
             slug={"bitbucket_id"}
             value={client.bitbucket_id}
             onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            /> */}
            <SettingField
          slug={'bitbucket_id'}
          title={'Bitbucket Workspace Slug'}
          value={client.bitbucket_id}
          type={FieldType.Text}
          onChange={(slug: string, ref: any, oldVal: any, newVal: any) => {
            this.onFieldChange(slug, newVal);
          }}
        />

      </>
    );
  }

  public onFieldChange(fieldSlug: string, newValue: string): any {
    let { updatedClient, client } = this.state;
    const oldVal = client[fieldSlug];
    if (oldVal !== newValue) {
      updatedClient[fieldSlug] = newValue;
      client[fieldSlug] = newValue;
      this.setState({ client: client, updatedupdatedClientUser: updatedClient });
    }
  }
}

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLogout, IconSettings, IconUser } from '../core/icons/Icons';
import Chooser from '../inputs/chooser/Chooser';
import Dropdown from '../inputs/dropdown/Dropdown';
import Search from '../inputs/search/Search';

export default function PageHeader(props: any) {
  let navigate = useNavigate();
  let [clientIndex, setClientIndex] = useState(-1);
  let [projectIndex, setProjectIndex] = useState(-1);
  const { logout } = useAuth0();

  useEffect(() => {
    if (clientIndex == -1) return;

    let client = props.clients[clientIndex];
    if (!client) return;
    setProjectIndex(-1);

    props.onClientChange(client);
  }, [clientIndex]);

  useEffect(() => {
    if (projectIndex == -1) return;
    let project = props.projects[projectIndex];
    if (!project) return;
    props.onProjectChange(project);
  }, [projectIndex]);

  useEffect(() => {
    if (props.clients.length == 0) return;
    if (clientIndex == -1) {
      setClientIndex(0);
      let client = props.clients[clientIndex];
      props.onClientChange(client);
    }
  }, [props.clients]);

  useEffect(() => {
    if (props.projects.length == 0) return;
    if (projectIndex == -1) {
      setProjectIndex(0);
      let project = props.projects[0];
      if (!project) return;
      props.onProjectChange(project);
    }
  }, [props.projects]);

  let clientOptions: any = props.clients
    ? props.clients.map((client: any, index: number) => {
        return { title: client.name, key: index, type: 0 };
      })
    : [];

  clientOptions.push({ type: 1 });
  clientOptions.push({
    title: 'My Profile',
    key: 'profile ',
    type: 2,
    icon: <IconUser />,
    action: () => {
      navigate('/profile');
    }
  });
  clientOptions.push({
    title: 'Settings',
    key: 'settings',
    type: 2,
    icon: <IconSettings />,
    action: () => {
      navigate('/config');
    }
  });
  clientOptions.push({
    title: 'Log out',
    key: 'log_out',
    type: 2,
    icon: <IconLogout />,
    action: () => {
      logout({ returnTo: window.location.origin });
    }
  });

  let projectOptions = props.projects
    ? props.projects.map((project: any, index: number) => {
        return {
          key: index,
          title: project.name
        };
      })
    : [];

  return (
    <header className={'pageHeader '}>
      <div className="topActions">
        <h2>Hello {props.scUser && props.scUser.firstName}</h2>
        <Search />

        <Dropdown
          options={clientOptions}
          defaultIndex={clientIndex}
          onOptionChange={(index: number, key: string, option: any) => {
            setClientIndex(index);
          }}
        />
      </div>
      <div className="bottomActions">
        <Chooser
          title="Project"
          placeholder="Select a project"
          options={projectOptions}
          defaultIndex={projectIndex}
          onOptionChange={(index, key, option) => {
            setProjectIndex(index);
          }}
        />

      </div>
    </header>
  );
}

import './FinancialStatus.scss';

import {  ScCost, ScTime, Team, TimeType, TimeFrequency } from '~/app/services/Types';
import { ReactComponent as IconDol } from '~/assets/icons/billing-ico.svg';
import { ReactComponent as IconCal } from '~/assets/icons/calendar-ico.svg';
import { ReactComponent as IconClock } from '~/assets/icons/time-ico.svg';
import { ReactComponent as IconPeople } from '~/assets/icons/users-ico.svg';

interface IProps {
  frequency?: TimeFrequency;
  timeType?: TimeType;
  costs: any[];
  team: Team;
  type: "normal" | "big";
}

export default function FinancialStatus(props: IProps) {

  const { frequency, timeType, costs, type } = props;
  const timeFrequency = frequency ? frequency : TimeFrequency.Monthly;
  const isBig = type == "big";
  let mainIcon = <IconCal />;
  if (frequency == TimeFrequency.Today || frequency == TimeFrequency.Weekly) {
    mainIcon = <IconClock />;
  }

  return (
      <div className="lists" time-type={TimeType[timeType]} frequency-type={TimeFrequency[timeFrequency]} data-big={isBig}>
        {
          costs.map((cost: ScCost, index: number) => {
            return (
              <div key={index} className={'cost '}>
                <div className={'label'}>
                  {mainIcon} {cost.label}
                </div>
                <div className={'right'}>
                  <div className={'people'}>
                    <IconPeople /> {cost.people} {((isBig && index == 0) && " people")}
                  </div>
                  <div className={'price'}>
                    {(isBig ? ( index != 0) && <IconDol /> : <IconDol />)} ${cost.cost}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
  );
}

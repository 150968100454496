
import { ScPage, ScSearchable, TimeFrequency } from "~/app/services/Types"
import BasePage, { BasePageState } from "../BasePage";
import { search } from '~/app/services/SearchService';
import UserCard from '~/app/components/cards/user/UserCard';
import JobCard from '~/app/components/cards/job/JobCard';
import ClientCard from '~/app/components/cards/client/ClientCard';
import ProjectCard from '~/app/components/cards/project/ProjectCard';
import Loading from "../../core/loading/Loading";


interface IProps extends ScPage{

}


interface IState extends BasePageState{
    keywords: string;
    results: ScSearchable[] | null
}

export default class PageSearch extends BasePage<IProps, IState>{

    protected dontUpdate = false;

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            timeFrequency: TimeFrequency.Monthly,
            keywords: "",
            results:  null
        }
    }

    public onInit(): void {


    }

    public info():any{
        const pageContext = this.props.context;
        const {page, appContext} = pageContext;

        return {
            title: "Search",

            icon: (null),
            className: "searchPage",
            showTop: false,
            renderCustom: true,
            columns:2,
            widgets: []
        }
    }

    public async onPostLoad() {
        const pageContext = this.props.context;
        const { appContext} = pageContext;
        let queryKeywords =  appContext.searchParams.get("s");

        await this.search(queryKeywords);
    }

    public async onUpdated() {
        const pageContext = this.props.context;
        const { appContext} = pageContext;
        let queryKeywords =  appContext.searchParams.get("s");

        await this.search(queryKeywords);
    }

    public async search(keywords:string){
        this.setState({keywords: keywords, loading:true}, async () => {
            const results: ScSearchable[] = await search(keywords);
            this.setState({results: results, loading:false});
        });
    }

    public onRender() {
        const {keywords, results, loading} = this.state;

        return (
            <>
            {keywords && keywords.length ?<h1>Searching for {keywords}</h1>:null}

            {loading || !results ?(
               <Loading center />
            ): results.length  ===  0 ? (
                <div className='noFound'>
                    <h2>No items found</h2>
                </div>
            ): (
                <div className='searchGrid'>
                    {
                    results.map((result: any, index:number) => {
                        if(result.type === "user")return <UserCard  key={index} user={result.data}/>
                        if(result.type === "job")return <JobCard  key={index} job={result.data}/>
                        if(result.type === "client")return <ClientCard  key={index} client={result.data}/>
                        if(result.type === "project")return <ProjectCard key={index}  project={result.data}/>
                        return <div key={index} className='searchResult'>{result.keywords}</div>
                    })
                    }
                </div>
            )}
            </>
        )
    }


}

import { ReactComponent as IconMonitor } from '~/assets/icons/monitor-ico.svg';
import {  ScPage, ScUser, TimeFrequency } from "~/app/services/Types"
import BasePage, { BasePageState } from "../../BasePage";
import { getUserBy } from '~/app/services/UserService';
import { getSTUsers } from '~/app/services/StreamTimeService';
import { FieldType, Grid, SettingField, Widget, WidgetStyles } from '~/app/components/core';


interface IProps extends ScPage{

}


interface IState extends BasePageState{
    user: ScUser;
    stUsers: any[];
}

export default class PageUserAdd extends BasePage<IProps, IState>{

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            stUsers:[],
            user: null,
            timeFrequency: TimeFrequency.Monthly,
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const { appContext} = pageContext;

        return {
            title: "Add User",

            icon: (<IconMonitor />),
            className: "",
            showTop: false,
            renderCustom: true,
            columns:2,
        }
    }

    public async onPostLoad() {

        const pageContext = this.props.context;
        const {appContext} = pageContext;

        let userEmail = appContext.searchParams.get('email');
        const stUsers = await getSTUsers();

        if (userEmail) {
            const user = await getUserBy("email", userEmail);
            this.setState({
                loading:false,
                user: user,
                stUsers: stUsers,
            });
        }else{
            //Not found
        }


    }

    public render(): React.ReactElement<IProps>  {
         const pageContext = this.props.context;
        const {page, appContext} = pageContext;
        const {slug} = this.props;
        const {icon, title, showTop, renderCustom} = this.info();
        const {loading, user, stUsers} = this.state;

        return (
            <div className="page" data-page={slug}>
                <main>
                    <div className="topArea">
                        <h3>
                            {icon} {title}
                        </h3>
                        {this.onRenderTop()}
                    </div>
                    <Grid gap={20} columns={1} key={`${user && user.email}_${loading}`}>
                        <Widget key={"userEdit"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Normal}
                                    title={"Edit User"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                               {user && this.getUserRender(user)}
                        </Widget>

                    </Grid>

                </main>
            </div>
        );
    }


    public onRenderTop() {

        return (
           <div>
            <button>
                Go back
            </button>
            <button>
                Save
            </button>
           </div>
        )
    }


public getUserRender(user: ScUser): any {
    const {stUsers} = this.state;
    const {onFieldChange} = this;
    return (
        <>
            <SettingField
                slug={"Id"}
                title={"ID"}
                readOnly
                value={user.email}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"email"}
                title={"Email"}
                value={user.email}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"firstName"}
                title={"First Name"}
                value={user.firstName}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                 slug={"lastName"}
                 title={"Last Name"}
                 value={user.lastName}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"mobile"}
                title={"Mobile"}
                value={user.mobile}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"position"}
                title={"Position Title"}
                value={user.position}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"gender"}
                title={"Gender"}
                value={user.gender}
                type={FieldType.ToggleSelect}
                options={[
                    {key: "Male", icon: <IconMonitor />},
                    {key: "Female", icon: <IconMonitor />},
                    {key: "Other", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"team"}
                title={"Team"}
                value={user.team ? user.team : ""}
                type={FieldType.ToggleSelect}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"px"}
                title={"Pixels"}
                value={20}
                type={FieldType.Number}
                suffix={"px"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"hours"}
                title={"Hours"}
                value={40}
                type={FieldType.Number}
                prefix={"🕐"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"hourly"}
                title={"Hourly rate"}
                value={52}
                type={FieldType.Number}
                prefix={"$"}
                suffix={".00"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"select"}
                title={"Select"}
                placeholder={"Choose an option"}
                value={user.team ? user.team : ""}
                type={FieldType.Select}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"multiSelect"}
                title={"Multi Select"}
                placeholder={"Choose some options"}
                value={[]}
                type={FieldType.MultiSelect}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"toggle"}
                title={"Toggle"}
                value={false}
                type={FieldType.Boolean}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"hireDate"}
                title={"Date hired"}
                value={"2022-10-01"}
                type={FieldType.Date}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"image"}
                title={"Image"}
                value={user.profile_image}
                type={FieldType.Image}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"streamtime_id"}
                title={"Streamtime User"}
                value={user.streamtime_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"employeehero_id"}
                title={"Employee Hero User"}
                value={user.employeehero_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"upwork_id"}
                title={"Upwork User"}
                value={user.upwork_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"authId"}
                title={"oAuth User"}
                value={user.authId}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

        </>
    )
}
public onFieldChange(type: 'user' | 'client' | 'project', identity:string, fieldSlug:string, newValue:string): any {
}


}






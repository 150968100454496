import React, { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { ReactComponent as IconSearch} from "~/assets/icons/search-ico.svg";
import { useNavigate } from "react-router-dom";



interface IProps {
}


export default function Search(props:IProps) {
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [keywords, setKeywords] = useState(null);
    let queryKeywords =  searchParams.get("s");

  useEffect(() => {
    if(queryKeywords){
        setKeywords(queryKeywords);
    }
  }, []);



    return (
        <div className='searchBox'>
            <IconSearch />
            <input type="text"
            placeholder='Search projects or team members'
            defaultValue={keywords}
            onChange={(e)=>setKeywords(e.target.value)} onKeyDown={(e)=>{
               if (e.key === 'Enter') {
                    navigate('/search?s='+keywords)
                }
                if (e.keyCode === 8) {
                    if(keywords.length === 1){
                        setKeywords("")
                        navigate('/search?s=')
                    }
                }
            }}/>
        </div>
    );


}

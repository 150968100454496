import React from "react";
import Grid from "../core/grid/Grid";
import {  IWidgetContext, IWidgetData, ScPage, TimeFrequency } from "../../services/Types";
import WidgetManager from "../../services/WidgetManager";
import ScoutClassComponent, { ScoutClassComponentState } from "../core/ScoutClassComponent";
import PageAuthStatus from "./auth/PageAuthStatus";

export interface BasePageState extends ScoutClassComponentState{
    timeFrequency: TimeFrequency;

}

export default abstract class BasePage<T extends ScPage, J extends BasePageState> extends ScoutClassComponent<T, J> {

    public info():any{
        return {
            title: "Base Page",
            icon: null,
            className: "",
            showTop: true,
            renderCustom: false,
            columns:3,
            widgets: []
        }
    }

    public onRenderGrid() {
        const pageContext = this.props.context;
        const {page, appContext} = pageContext;
        const {timeFrequency} = this.state;
        const {widgets, columns} = this.info();
        const {client, project} = this.props.context;
        if(!client || !project){
          return (<div className="page" data-page={page.slug}><PageAuthStatus title={"No Project or Client Selected"} message={"Please select a client and project to view this page."}/></div>)
        }

        return (
            <Grid columns={columns}>
                {widgets.map((widget:IWidgetData) => {

                    const widgetContext:IWidgetContext = {
                        appContext: appContext,
                        pageContext: pageContext,

                        page: pageContext.page,
                        client: pageContext.client,
                        project: pageContext.project,
                        timeFrequency: timeFrequency,
                        size: widget.size,
                        match: widget.match,
                        widgetStyle: widget.widgetStyle,
                        data: widget.data,
                    }

                    return WidgetManager.buildWebpart(widget.slug, widgetContext);
                })}
            </Grid>
        )
    }


    public render(): React.ReactElement<T>
    {
        const {slug} = this.props;
        const {icon, title, showTop, renderCustom} = this.info();
        const {loading} = this.state;

        return (
            <div className="page" data-page={slug}>
                <main>

                    {showTop && (
                        <div className="topArea">
                            <h3>
                                {icon} {title}
                            </h3>
                            {this.onRenderTop()}
                        </div>
                    )}
                    {renderCustom && this.onRender()}
                    {!renderCustom && this.onRenderGrid()}

                </main>
            </div>
        );
    }


}

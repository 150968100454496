/**
 * Scout Search Service
 */

import { cache, cached, call, EP, sort } from "./API";
import { getClients } from "./ClientService";
import { getJobs, getProjects } from "./ProjectService";
import { ScClient, ScJob, ScProject, ScSearchable, ScUser } from "./Types";
import { getUsers } from "./UserService";

/** API */

export const getAll = async (): Promise<any>  => {
    const cacheKey = `all`;
    if(cacheKey && cached(cacheKey)) return cached(cacheKey);

    let clients = await getClients();
    let projects = await getProjects();
    let jobs = await getJobs();
    let users = await getUsers();

    const all = {
        clients:clients,
        projects:projects,
        jobs:jobs,
        users:users,
    }

    cache(cacheKey, all);
    return all;
}




/** Static */
export const getSearchable = async (): Promise<ScSearchable[]>  => {
    const cacheKey = `allSearchable`;
    if(cacheKey && cached(cacheKey)) return cached(cacheKey);

    let allToSearch:ScSearchable[] = [];
    const all = await getAll();

    all.clients.map((obj:ScClient) =>{
        allToSearch.push({
            type:"client",
            data: obj,
            keywords: obj.name,
        });
    });
    all.projects.map((obj:ScProject) =>{
        allToSearch.push({
            type:"project",
            data: obj,
            keywords: obj.name,
        });
    });

    all.users.map((obj:ScUser) =>{
        allToSearch.push({
            type:"user",
            data: obj,
            keywords: obj.firstName + " " + obj.lastName,
        });
    });
    all.jobs.map((obj:ScJob) =>{
        allToSearch.push({
            type:"job",
            data: obj,
            keywords: obj.name,
        });
    });

    cache(cacheKey, allToSearch);
    return allToSearch;
}


export const search = async (keywords:string): Promise<ScSearchable[]>  => {
    let allToSearch:ScSearchable[] = await getSearchable();
    let results:ScSearchable[] = [];

    keywords = (keywords+"").toLowerCase().trim();

    if(keywords.length === 0)return allToSearch;


    allToSearch.map((result: ScSearchable) => {
        if(!result.keywords) return;
        if(result.keywords.toLowerCase().includes(keywords)){
            results.push(result);
        }
    });
    return results;
}




/** Utils */

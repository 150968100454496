
import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import moment from 'moment';
import { Bar } from "react-chartjs-2";
import {  ScTime,TimeFrequency, TimeType } from '~/app/services/Types';
import Util from '~/app/services/Util';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);





interface ITimeChartProps{
    frequency?: TimeFrequency;
    type?: TimeType;
    maximum?: number;
    times: ScTime[];
}

export default function TimeUsageChart(props:ITimeChartProps) {

    const {frequency, type, maximum, times} = props;
    const max = maximum ? maximum : 5;
    const timeType = type ? type : TimeType.Teams;
    const chartData:any = Util.generateBarChartData(trimTimes());

    function getLabel(time:ScTime){
        if(frequency == TimeFrequency.Monthly){
            return Util.getMonthFromDate(time.startDate);
        }else if(frequency == TimeFrequency.Weekly){
            return Util.getWeeksAgo(time.startDate);
        }else if(frequency == TimeFrequency.Today){
            return moment(time.loggedTime).format("h:00 A");
        }
        return "NA";
    }

    function trimTimes(){
        let labels: string[] = [];
        let dataRed: string[] = [];
        let dataBlue: string[] = [];
        let dataUser: string[] = [];
        let timesForChart = [...times];

        if(timesForChart.length)timesForChart = timesForChart.splice(-max);

        timesForChart.map((time, index) =>{
            if(index >= max)return time;
            if(time.minutes == 0)return time;

            const team = time.team ? time.team : "red";
            let label = getLabel(time);


            const timeValue = (time.minutes/60).toString();

            labels.push(label);

            if(type == TimeType.User){
                dataUser.push(timeValue)
            }else{

                if(team == "blue"){
                    dataBlue.push(timeValue);
                }else{
                    dataRed.push(timeValue);
                }
            }
            return time;
        });

        return {
            labels: labels,
            sets:{
                redTeam: {
                    type: "Red",
                    data: dataRed
                },
                blueTeam: {
                    type: "Blue",
                    data: dataBlue
                },
                user:{
                    type: "User",
                    data: dataUser
                }
            }

        }
    }


    return (
        <div className="TimeChart" time-type={TimeType[timeType]} frequency-type={TimeFrequency[frequency]}>
            <Bar data={chartData.data} height={150} options={chartData.options} />
        </div>
    );
}

import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import './TimeUsageRadial.scss';

interface ITimeUsageProps {}

export default function TimeUsageRadial(props: ITimeUsageProps) {
  // const {title, widgetStyle} = props;

  return (<>
    {/* @ts-ignore*/}
    <Chart options={getChartOptions()} series={getChartData()} type="radialBar" width={'100%'} height={'auto'} />
    </>);
}

const getChartOptions = (): ApexOptions => {
  return {
    chart: {
      events: {
        click: (e, chart, opt) => {
        }
      }
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } }
    },
    plotOptions: {
      radialBar: {
        offsetY: -30,
        hollow: {
          margin: 0,
          background: 'transparent',
          size: '30px'
        },
        track: {
          background: '#FFD7B3',
          strokeWidth: '100%',
          margin: 15,
          opacity: 1
        },
        dataLabels: {
          name: {
            fontFamily: 'Montserrat',
            fontSize: '12px',
            color: '#0F131C'
          },
          value: {
            fontFamily: 'Montserrat',
            fontSize: '10px',
            color: '#0F131C'
          },
          total: {
            show: false
          }
        }
      }
    },
    colors: ['var(--secondary-orange-dark)', 'var(--secondary-orange)'],
    fill: {
      colors: ['var(--secondary-orange-dark)', 'var(--secondary-orange)']
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Time Usage', 'Daily Average'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '12px',
      floating: true,
      markers: {
        width: 18,
        height: 5,
        radius: 4
      },
      itemMargin: {
        vertical: 5,
        horizontal: 10
      },
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    }
  };
};

const getChartData = () => {
  return [32, 92];
};

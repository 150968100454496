import moment from "moment";
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { IAppContext } from "../Types";




const AppContext = createContext<any>(null);

const defaultContext = {
    timeUpdate: undefined,
    page: undefined,
    client: undefined,
    project: undefined,
    user: undefined,
    clients: undefined,
    projects: undefined,
    searchParams: undefined,
    navigate: undefined

};

export const AppContextProvider = ({ children, context }) => {
  const [currentContext, setCurrentContext] = useState<IAppContext>(
    context || defaultContext
  );

  useEffect(()=> {
    context.timeUpdate = moment().format("YYYY-MM-DD HH:mm:ss");
    setCurrentContext(context)
  }, [context])

  const saveAppContext = (values:IAppContext) => {
    context.timeUpdate = moment().format("YYYY-MM-DD HH:mm:ss");
    setCurrentContext(values)
  };

  console.log("App context updated", context, currentContext, defaultContext)

  return (
    <AppContext.Provider
      value={{ appContext: currentContext, saveAppContext }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const ContextConsumer = AppContext.Consumer;

export default AppContext;

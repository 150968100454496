import { NavLink } from 'react-router-dom';
import { ScUser } from '~/app/services/Types';
import Util from '../../../services/Util';


interface IProps {
    user: ScUser;
    timeLogged?: number;
    lastEntry?: string;
    dailyAverage?: number;
}


export default function UserCard(props:IProps) {
    const user = props.user;
    // const name = user.firstName + " "+ user.lastName;//add a perm for only first name
    const name = user.firstName;
    const team = Util.getUserTeam(user);
    const image = user.profile_image;
    return (
        <div key={user.email} 
            className={'cardUser '+ user.gender + " " + (user.team)}
            >
            <div className={'profilePicture ' + (!image ? "initials" : null) }
                 style={{backgroundImage: `url(${image})`}} >
                {(!image ? user.firstName[0].toUpperCase() : null)}
            </div>
            <div className='cardUserContent'>
                <div className='cardUserTeam'>{team}</div>
                <div className='cardUserName'>{name}</div>
                <div className='cardUserPosition'>{user.position}</div>
            </div>
            <div className='cardUserTimes'>
                {props.lastEntry  ? <div className='cardUserLastEntry'>Last time entry  {Util.getTimeAgo(props.lastEntry)}</div> : null}
                {props.timeLogged  ? <div className='cardUserInfo'>
                    <span>Time Logged</span>
                    <span>{Util.getFormattedFromMins(props.timeLogged, true)}</span>
                </div> : null}
                {props.dailyAverage  ? <div className='cardUserInfo'>
                    <span>Daily Average</span>
                    <span>{Util.getFormattedFromMins(props.dailyAverage, true)}</span>
                </div> : null}
            </div>
            <NavLink 
                className={"bigclicker"}
                to={"/user?email="+user.email} > </NavLink>
        </div>
    );


}
import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import App from './app/App';

ReactDOM.render(
<Auth0Provider 
    domain="hideandseekauth-dev.au.auth0.com"
    clientId="r0BSX9KYXJP5zL6iCw4EQxWcWOUbOvLf"
    redirectUri={window.location.origin}>
    <App />
</Auth0Provider>
, document.getElementById('root'));

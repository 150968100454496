import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { getTimesByGroupUsers, getTimesByMultiUser, getTimesByUser } from "~/app/services/TimeService";
import { ScTime, ScUser, ScUserTime, ScWidget, Team, TimeType } from "~/app/services/Types";
import { getUsersBy } from "~/app/services/UserService";
import Util from "~/app/services/Util";
import Notice from "../../core/notice/Notice";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import UsersList from "./components/UserList";

interface IProps extends ScWidget{

}

interface IState extends BaseWidgetState{

    showAll: boolean;
    times: ScTime[];
    userTimes: ScUserTime[];
    users: ScUser[];
}

export default class UserListWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            showAll: false,
            users: [],
            times: [],
            userTimes: [],
        }
    }

    public info(){
        const widgetContext = this.props.context;

        return   {
            title: Team[widgetContext.data.team] + " Team",
            className: "UserList",
            defaultStyle: WidgetStyles.Normal,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {
        this.dontUpdate = true;
        this.setState({
            showAll: false,
            times: [],
            userTimes: [],
        });

        const {project, timeFrequency} = context;
        if(!project)return;

        const users = await getUsersBy("projectId", project.projectId)
        this.setState({  users: users, loading:false });

        const times = await getTimesByGroupUsers("projectId", project.projectId, timeFrequency);
        this.setState({  times: times });

        let newUsers = Util.sortUsersByTimeLogged(users, times);

        this.setState({  users: newUsers });

        const userTimes = await getTimesByMultiUser("projectId", project.projectId, timeFrequency, users);

        this.setState({ userTimes: userTimes });
    }

    public onRender(context: ScWidgetContext) {
        const {userTimes, users, times, showAll} = this.state;
        const widgetContext = this.props.context;

        if(!users || users.length === 0){
          return (
            <Notice
              title={"No "+ Team[widgetContext.data.team] + " Team Users"}
              message={"There are no "+ Team[widgetContext.data.team] + " Team users yet"}
              actionLink={`/config/project?id=${widgetContext.project && widgetContext.project.projectId}`}
              actionText={"Add users"}
            />
          )
        }

        return (
            <UsersList userTimes={userTimes} users={users} team={widgetContext.data.team} times={times} showAll={showAll}/>
        )
    }

}

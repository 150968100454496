import { ReactComponent as IconBilling } from '~/assets/icons/billing-ico.svg';
import ToggleGroup from "~/app/components/inputs/toggleGroup/ToggleGroup";
import FeatureManager from "~/app/services/FeatureManager";
import { ScPage, Team, TimeFrequency } from "~/app/services/Types"
import Util from "~/app/services/Util";
import BasePage, { BasePageState } from "../BasePage";


interface IProps extends ScPage{

}


interface IState extends BasePageState{

}

export default class PageFinance extends BasePage<IProps, IState>{


    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            timeFrequency: TimeFrequency.Monthly,
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const {page, appContext} = pageContext;

        const widgets = [
            { slug:"financialStatus", size:6, data:{type:"big", team:Team.Red} },
            { slug:"financialStatus", size:6, data:{type:"big", team:Team.Blue} },

            { slug:"userList", size:6, data:{team:Team.Red}  },
            { slug:"userList", size:6, data:{team:Team.Blue} },
        ];

        const filteredWidgets: any = widgets.map(widget => {
            const widgetPermission = FeatureManager.userHasFeature(appContext.user, this.props.slug+":page:widget_"+widget.slug);
            if(!widgetPermission)return;
            return widget;
        });

        return {
            title: "Finance",

            icon: (<IconBilling />),
            className: "Finance",
            showTop: true,
            renderCustom: false,
            columns:2,
            widgets: filteredWidgets
        }
    }

    public onRenderTop() {

        return (
            <ToggleGroup
                options={Util.getTimeFrequencyAsArray()}
                defaultValue={TimeFrequency.Monthly}
                onOptionChange={(key: any, text: string) => {
                    this.setState({ timeFrequency:key });
                }}
            />
        )
    }


}


import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { TimeFrequency, TimeType } from '~/app/services/Types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);




interface ICommitsChartProps{
    frequency?: TimeFrequency;
    type?: TimeType;
    data?: any;
}


export default function CommitsChart(props:ICommitsChartProps) {

    const {frequency, type, data} = props;
    const timeType = type ? type : TimeType.Teams;
    const timeFrequency = frequency ? frequency : TimeFrequency.Monthly;
    const chartData:any = data;

    return (
        <div className="CommitChart" time-type={TimeType[timeType]} frequency-type={TimeFrequency[timeFrequency]}>
            <Line data={chartData.data} options={chartData.options} height={150} />
        </div>
    );
}
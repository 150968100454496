import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { getTimesBy, getTimesByMultiUser } from "~/app/services/TimeService";
import { ScTime, ScUserTime, ScWidget, TimeFrequency, TimeType} from "~/app/services/Types";
import Notice from "../../core/notice/Notice";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import TimeUsageChart from "./components/chart/TimeUsageChart";
import TimeUsageRadial from "./components/radial/TimeUsageRadial";


interface IProps extends ScWidget{

}


interface IState extends BaseWidgetState{
    times: ScTime[];

}

export default class TimeUsageWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            times: []
        }
    }

    public info(){
        return   {
            title: "Time Usage",
            className: "TimeUsage",
            defaultStyle: WidgetStyles.Orange,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {
        const widgetContext = this.props.context;
        const {project, data, timeFrequency} = widgetContext;
        const {type, timeType, user} = data;

        if(!project)return;

        if(timeType === TimeType.User){
            const userTimes:ScUserTime[] = await getTimesByMultiUser("projectId", project.projectId, timeFrequency, [user]);
            if(!userTimes || !userTimes[0] || userTimes[0].times){
              this.setState({loading:false});
              return;
            };
            this.setState({loading:false, times: userTimes[0].times});

        }else{
            const times:ScTime[] = await getTimesBy("projectId", project.projectId, timeFrequency, 5);
            if(!times){
              this.setState({loading:false});
              return;
            };

            this.setState({loading:false, times: times});

        }
    }

    public onRender(context: ScWidgetContext) {
        const widgetContext = this.props.context;
        const {data, timeFrequency, project} = widgetContext;
        const {type, timeType, user} = data;
        const {times} = this.state;

        if(!project.streamTimeId){
          return (
            <Notice
              title={"StreamTime not connected"}
              message={"To see the project time usage of this project please connect with your StreamTime job."}
              actionLink={`/config/project?id=${project.projectId}`}
              actionText={"Setup StreamTime"}
            />
          )
        }

        if(times.length === 0){
          return (
            <Notice
              title={"No times"}
              message={"No times tracked yet for this project."}
            />
          )
        }

        if(type === "chart"){
            return (
                <TimeUsageChart frequency={timeFrequency} type={timeType} maximum={5} times={times}/>
            )
        }
        return (
            <TimeUsageRadial />

        )
    }

}

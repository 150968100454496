
import "./Loading.scss";


interface IProps {
    center?: boolean;
}

export default function Loading(props:IProps) {

    return (
        <div className={"loading-ripple center-"+props.center}>
            <div></div>
            <div></div>
        </div>
    );
}
import { useNavigate } from "react-router-dom";
import './Button.scss';

export enum ButtonStyle {
  normal,
  primary,
  secondary,
  danger, 
  disabled, 
}

interface IProps {
  text?:string;
  style?:ButtonStyle;
  className?:string;
  icon?:any;
  onClick?:()=>void;
  to?:string;
  disabled?: boolean;
}


export default function Button(props:IProps) {
  const navigate = useNavigate();
  let { text, className, icon, onClick, to, style, disabled} = props;
  style = disabled ? ButtonStyle.disabled : style || ButtonStyle.normal;

  return <button 
    disabled={disabled} 
    className={["button", className].join(" ")} 
    button-style={ButtonStyle[style]} 
    onClick={(e) => {
      if(disabled != undefined || disabled){
        e.preventDefault();
        return;
      }
      onClick && onClick();
      to && navigate(to);
    }}>
    {icon}
    <span>
      {text}
    </span>
    </button>;
};


import React, { ClassType } from "react";
import CapacityUsageWidget from "../components/widgets/CapacityUsage/CapacityUsageWidget";
import CountWidget from "../components/widgets/Count/CountWidget";
import FinancialStatusWidget from "../components/widgets/FinancialStatus/FinancialStatusWidget";
import GitWidget from "../components/widgets/Git/GitWidget";
import GridWidget from "../components/widgets/Grid/GridWidget";
import RecentActivityWidget from "../components/widgets/RecentActivity/RecentActivityWidget";
import TimeUsageWidget from "../components/widgets/TimeUsage/TimeUsageWidget";
import UserBannerWidget from "../components/widgets/UserBanner/UserBannerWidget";
import UserListWidget from "../components/widgets/UserList/UserListWidget";
import { IWidgetContext, ScWidgets } from "./Types";


export default class WidgetManager {

    private static widgetRegister: ScWidgets = {
        financialStatus: FinancialStatusWidget,
        capacityUsage: CapacityUsageWidget,
        timeUsage: TimeUsageWidget,
        recentActivity: RecentActivityWidget,
        userList: UserListWidget,
        git: GitWidget,
        count: CountWidget,
        grid: GridWidget,
        userBanner: UserBannerWidget,
    }

    public static buildWebpart(key:string, widgetContext:IWidgetContext){
        //move feature and perms checks to here
        const WidgetClass: ClassType<any, any, any>  = this.widgetRegister[key];
        if (typeof WidgetClass !== "undefined") {

            return React.createElement(WidgetClass, {
                key: key,
                slug: key,
                context:widgetContext,
                "data-size": widgetContext.size
            });
        }

        return (
            <div>Widget {key} has not been implimented yet.</div>
        );
    }


 

}
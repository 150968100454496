import ToggleGroup, { IToggleStyle } from "~/app/components/inputs/toggleGroup/ToggleGroup";
import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { getCostsByTimes, getTimesBy } from "~/app/services/TimeService";
import { ETeamFull, ScCost, ScTime, ScWidget, Team, TimeType } from "~/app/services/Types";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import Count from "./components/Count";

interface IProps extends ScWidget{

}


interface IState extends BaseWidgetState{
    count:number;

}

export default class CountWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            count:0
        }
    }

    public info(){
        const widgetContext = this.props.context;
        return   {
            title: widgetContext.data.title,
            className: "CountWidget",
            defaultStyle: WidgetStyles.Normal,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {
        const widgetContext = this.props.context;

        this.setState({loading:false, count: widgetContext.data.count});
    }

    public onRender(context: ScWidgetContext) {
        const {count} = this.state;
        return (
            <Count count={count} />
        )
    }

}

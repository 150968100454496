import ToggleGroup, { IToggleStyle } from "~/app/components/inputs/toggleGroup/ToggleGroup";
import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { getCostsByTimes, getTimesBy } from "~/app/services/TimeService";
import { ETeamFull, ScCost, ScTime, ScWidget, Team, TimeType } from "~/app/services/Types";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import FinancialStatus from "./components/FinancialStatus";
import Notice from "../../core/notice/Notice";

interface IProps extends ScWidget{

}

interface IState extends BaseWidgetState{
    times: ScTime[];
    team: Team;
    costs: ScCost[];

}

export default class FinancialStatusWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            times: [],
            team: Team.Red,
            costs: [],
        }
    }

    public info(){
        const widgetContext = this.props.context;
        const {data} = widgetContext;
        const isBig = data.type  ===  "big";

        return   {
            title:isBig ? Team[data.team] + " Team" : "Financial Status",
            className: "FinancialStatus",
            defaultStyle: WidgetStyles.Normal,
            topLayout: "column"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadCosts(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadCosts(context);
    }

    public async loadCosts(context: ScWidgetContext) {
        const {timeFrequency} = context;
        const maxRows = 5;

        this.dontUpdate = true;
        this.setState({
            times: [],
            costs: [],
            loading:true
        }, async () => {

            let project = context.appContext.project;
            if (!project) return;

            const times = await getTimesBy("projectId", project.projectId, timeFrequency, maxRows);

            this.dontUpdate = true;
            this.setState({
                times: times,
                costs: times && getCostsByTimes(times, maxRows, timeFrequency),
                loading:false
            });


        });
    }

    public onRenderTop(context: ScWidgetContext) {
        const type = context.data.type;
        if(type !== "normal")return;
        return (
            <ToggleGroup
                options={ETeamFull()}
                defaultValue={Team.Red}
                style={IToggleStyle.Tabs}
                onOptionChange={(key: any, text: string) => {
                    this.dontUpdate = true;
                    this.setState({
                        team: key
                    })
                }}
            />
        )
    }

    public onRender(context: ScWidgetContext) {
        const {costs, team} = this.state;
        const project = this.props.context.project;
        const type = context.data.type;
        if(!costs){
          return (
            <Notice
              title={"StreamTime not connected"}
              message={"To see the project financials of this project please connect with your StreamTime job."}
              actionLink={`/config/project?id=${project.projectId}`}
              actionText={"Setup StreamTime"}
            />
          )
        }
        if(costs.length === 0){
          return (
            <Notice
              title={"No "+ Team[team] + " Team Costs"}
              message={"There are no "+ Team[team] + " Team costs yet"}
            />
          )
        }
        return (
            <FinancialStatus type={type} costs={costs} team={team} timeType={TimeType.Teams}/>
        )
    }

}

import ToggleGroup from "~/app/components/inputs/toggleGroup/ToggleGroup";
import { WidgetStyles } from "~/app/components/core/widget/Widget";
import FeatureManager from "~/app/services/FeatureManager";
import { ScPage, Team, TimeFrequency } from "~/app/services/Types"
import Util from "~/app/services/Util";
import { ReactComponent as IconBrowse } from '~/assets/icons/browseall-ico.svg';
import BasePage, { BasePageState } from "../BasePage";


interface IProps extends ScPage{

}


interface IState extends BasePageState{

}

export default class PageOverview extends BasePage<IProps, IState>{

    protected dontUpdate = false;

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            timeFrequency: TimeFrequency.Monthly,
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const {page, appContext} = pageContext;

        const widgets = [
            { slug:"timeUsage", data:{type:"radial"} },
            { slug:"capacityUsage",  },
            { slug:"financialStatus",  widgetStyle:WidgetStyles.Blue, data:{type:"normal"}  },
            { slug:"recentActivity", size:12, data:{}},
            { slug:"userList", size:6, data:{team:Team.Red}  },
            { slug:"userList", size:6, data:{team:Team.Blue} },
        ];

        const filteredWidgets: any = widgets.map(widget => {
            const widgetPermission = FeatureManager.userHasFeature(appContext.user, this.props.slug+":page:widget_"+widget.slug);
            if(!widgetPermission)return;
            return widget;
        });

        return {
            title: "Overview",
            icon: (<IconBrowse />),
            className: "",
            showTop: true,
            renderCustom: false,
            columns:3,
            widgets: filteredWidgets
        }
    }

    public onRenderTop() {

        return (
            <ToggleGroup
                options={Util.getTimeFrequencyAsArray()}
                defaultValue={TimeFrequency.Monthly}
                onOptionChange={(key: any, text: string) => {
                    this.setState({ timeFrequency:key });
                }}
            />
        )
    }


}

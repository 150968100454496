import { ReactComponent as IconMonitor } from '~/assets/icons/monitor-ico.svg';
import { ReactComponent as IconBack } from '~/assets/icons/arrowleft-ico.svg';
import { ReactComponent as IconSave } from '~/assets/icons/check-ico.svg';
import { ReactComponent as IconDelete } from '~/assets/icons/delete-ico.svg';
import {  BbUser, ScPage, ScUser, TimeFrequency } from "~/app/services/Types"
import BasePage, { BasePageState } from "../../BasePage";
import { getUserBy, updateUser } from '~/app/services/UserService';
import { getEHUsers, getSTUsers } from '~/app/services/StreamTimeService';
import { FieldType, Grid, SettingField, Widget, WidgetStyles } from '~/app/components/core';
import Button, { ButtonStyle } from '~/app/components/inputs/button/Button';
import { notify, notifyError, notifySuccess } from '~/app/services/ToastService';
import { urlQuery } from '~/app/services/API';
import { getAuthUsers } from '~/app/services/AuthService';
import { getBBUsers } from '~/app/services/BitBucketService';
import { AuthOUserSelecter, BitBucketUserSelecter, EmployeeHeroUserSelecter, StreamTimeUserSelecter } from '~/app/components/core/settingField/SettingField';


interface IProps extends ScPage{

}


interface IState extends BasePageState{
    user: ScUser;
    updatedUser: any;
}

export default class PageUserEdit extends BasePage<IProps, IState>{

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            user: null,
            updatedUser: {},
            timeFrequency: TimeFrequency.Monthly,
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const { appContext} = pageContext;

        return {
            title: "Edit User",

            icon: (<IconMonitor />),
            className: "",
            showTop: false,
            renderCustom: true,
            columns:2,
        }
    }

    public async onPostLoad() {

        const pageContext = this.props.context;
        const {appContext} = pageContext;
        let searchParams = appContext.searchParams;
        if(!searchParams || !searchParams.get('id')){
          searchParams = urlQuery();
        }

        let userEmail = searchParams.get('email');

        if (userEmail) {
            const user = await getUserBy("email", userEmail);
            this.setState({
                loading:false,
                user: user,

            });
        }else{
            //Not found
            notifyError(`User not found '${userEmail}'`, appContext.searchParams)
        }


    }

    public render(): React.ReactElement<IProps>  {
         const pageContext = this.props.context;
        const {page, appContext} = pageContext;
        const {slug} = this.props;
        const {icon, title, showTop, renderCustom} = this.info();
        const {loading, user} = this.state;

        return (
            <div className="page" data-page={slug}>
                <main>
                    <div className="topArea">
                        <h3>
                            {icon} {title}
                        </h3>
                        {this.onRenderTop()}
                    </div>
                    <Grid gap={20} columns={1} key={`${user && user.email}_${loading}`}>
                        <Widget key={"userGeneral"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Settings}
                                    title={"General"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                               {user && this.getUserGeneral(user)}
                        </Widget>
                        <Widget key={"userLinks"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Settings}
                                    title={"Accounts"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                               {user && this.getUserLinks(user)}
                        </Widget>
                        <Widget key={"userContact"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Settings}
                                    title={"Contact Info"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                               {user && this.getUserContact(user)}
                        </Widget>
                        <Widget key={"userPersonal"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Settings}
                                    title={"Personal Info"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                               {user && this.getUserPersonal(user)}
                        </Widget>
                        <Widget key={"userMisc"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Settings}
                                    title={"Miscellaneous"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                               {user && this.getUserMisc(user)}
                        </Widget>
                    </Grid>

                </main>
            </div>
        );
    }


    public onRenderTop() {

        return (
           <div style={{gap:20, display:"flex", flexWrap:"wrap", marginRight:"20px"}}>
            <Button text='Go back' style={ButtonStyle.normal} to={"/config"} icon={<IconBack/>} onClick={() => {
              notify("User changes discarded.")
            }}/>
            <Button text='Save User' style={ButtonStyle.primary} icon={<IconSave/>} onClick={() => {
              this.saveUser();
            }}/>
            <Button text='Delete User' style={ButtonStyle.danger} icon={<IconDelete/>} onClick={() => {
              notifyError("User deleted.")
            }}/>
           </div>
        )
  }

  public saveUser() {
    const {updatedUser, user} = this.state;
    if(!user)return;
    updatedUser["email"] = user.email;
    updateUser(user.email, updatedUser);
    notifySuccess("User saved!");
    this.setState({updatedUser: {}});
  }


public getUserGeneral(user: ScUser): any {
    const {onFieldChange} = this;
    return (
        <>
            <SettingField
                slug={"Id"}
                title={"ID"}
                readOnly
                value={user.id}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"firstName"}
                title={"First Name"}
                value={user.firstName}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                 slug={"lastName"}
                 title={"Last Name"}
                 value={user.lastName}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"position"}
                title={"Position Title"}
                value={user.position}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"team"}
                title={"Team"}
                value={user.team ? user.team : ""}
                type={FieldType.ToggleSelect}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"hireDate"}
                title={"Date hired"}
                value={"2022-10-01"}
                type={FieldType.Date}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />

        </>
    )
}

public getUserMisc(user: ScUser): any {
    const {onFieldChange} = this;
    return (
        <>

            <SettingField
                slug={"hours"}
                title={"Hours"}
                value={40}
                type={FieldType.Number}
                prefix={"🕐"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"hourly"}
                title={"Hourly rate"}
                value={52}
                type={FieldType.Number}
                prefix={"$"}
                suffix={".00"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />

        </>
    )
}

public getUserContact(user: ScUser): any {
    const {onFieldChange} = this;
    return (
        <>
            <SettingField
                slug={"email"}
                title={"Email"}
                value={user.email}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"mobile"}
                title={"Mobile"}
                value={user.mobile}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
        </>
    )
}

public getUserLinks(user: ScUser): any {
    return (
        <>
            <StreamTimeUserSelecter
             slug={"streamtime_id"}
             value={user.streamtime_id}
             onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <EmployeeHeroUserSelecter
             slug={"employeehero_id"}
             value={user.employeehero_id}
             onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <AuthOUserSelecter
             slug={"authId"}
             value={user.authId}
             onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            <SettingField
                slug={"upwork_id"}
                title={"Upwork User"}
                value={user.upwork_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={[].map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />

            <BitBucketUserSelecter
             workspaceSlug={"hide-and-seek"}
             slug={"bitbucket_id"}
             value={user.bitbucket_id}
             onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />


        </>
    )
}

public getUserPersonal(user: ScUser): any {
    return (
        <>

            <SettingField
                slug={"gender"}
                title={"Gender"}
                value={user.gender}
                type={FieldType.ToggleSelect}
                options={[
                    {key: "Male", icon: <IconMonitor />},
                    {key: "Female", icon: <IconMonitor />},
                    {key: "Other", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />

            <SettingField
                slug={"image"}
                title={"Image"}
                value={user.profile_image}
                type={FieldType.Image}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />

        </>
    )
}


public onFieldChange(fieldSlug:string, newValue:string): any {
  let {updatedUser, user} = this.state;
  const oldVal = user[fieldSlug];
  if(oldVal !== newValue){
    updatedUser[fieldSlug] = newValue;
    user[fieldSlug] = newValue;
    this.setState({user:user, updatedUser:updatedUser});
  }
}


}







import { ScClient, ScPage, ScProject, ScUser, TimeFrequency } from "~/app/services/Types"
import BasePage, { BasePageState } from "../BasePage";
import Widget, { WidgetStyles } from '~/app/components/core/widget/Widget';
import { getUsers } from '~/app/services/UserService';
import { getClients } from '~/app/services/ClientService';
import { getProjects } from '~/app/services/ProjectService';
import Accordion from '../../core/accordion/Accordion';
import Grid from '../../core/grid/Grid';
import SettingField, { FieldType } from '../../core/settingField/SettingField';
import { getSTUsers } from '~/app/services/StreamTimeService';
import Button, { ButtonStyle } from '../../inputs/button/Button';
import { IconMonitor, IconSave } from "../../core/icons/Icons";

interface IProps extends ScPage{

}


interface IState extends BasePageState{
    users: ScUser[];
    stUsers: any[];
    projects: ScProject[];
    clients: ScClient[];
}

export default class PageConfiguaration extends BasePage<IProps, IState>{


    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            stUsers:[],
            users:[],
            clients:[],
            projects:[],
            timeFrequency: TimeFrequency.Monthly,
        }
    }

    public info():any{
        const pageContext = this.props.context;
        const { appContext} = pageContext;

        return {
            title: "Configuration",

            icon: (<IconMonitor />),
            className: "",
            showTop: false,
            renderCustom: true,
            columns:2,
        }
    }

    public async onPostLoad() {
        const pageContext = this.props.context;
        const {appContext} = pageContext;

        const users = await getUsers();
        const stUsers = await getSTUsers();
        const clients = await getClients();
        const projects = await getProjects();

        this.setState({
            loading:false,
            users: users,
            stUsers: stUsers,
            clients: clients,
            projects: projects,
        });
    }

    public render(): React.ReactElement<IProps>  {
         const pageContext = this.props.context;
        const {page, appContext} = pageContext;
        const {navigate} = appContext;
        const {icon, title, showTop, renderCustom} = this.info();
        const {loading, users, clients, projects, stUsers} = this.state;

        return (
            <div className="page" data-page={"config"}>
                <main>
                    <div className="topArea">
                        <h3>
                            {icon} {title}
                        </h3>
                        {this.onRenderTop()}
                    </div>
                    <Grid gap={20} columns={2} key={`${clients.length}_${projects.length}_${users.length}_${loading}`}>
                        <Widget key={"general"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Settings}
                                    title={"General"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                                <SettingField
                                    slug={"companyName"}
                                    title={"Company Name"}
                                    value={"____"}
                                    type={FieldType.Text}
                                    onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {}}
                                />
                               <SettingField
                                    slug={"scoutKey"}
                                    title={"Scout Key"}
                                    value={"xxxx-xxxx-xxxx-xxxx"}
                                    type={FieldType.Text}
                                    onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {}}
                                />

                        </Widget>
                        <Widget key={"clients"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Normal}
                                    title={"Clients"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                                <Grid gap={10} columns={1} key={`${clients.length}_${loading}`}>
                                    {clients && clients.map(client => {

                                            return (
                                                <Accordion data-size={12} onClick={() => navigate('/config/client?id='+client.clientId)} key={client.clientId} title={client.name}>
                                                </Accordion>
                                            )
                                    })}
                               </Grid>
                        </Widget>
                        <Widget key={"projects"}
                                    data-size={6}
                                    widgetStyle={WidgetStyles.Normal}
                                    title={"Projects"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                                <Grid gap={10} columns={1} key={`${projects.length}_${loading}`}>
                                    {projects && projects.map(project => {

                                            return (
                                                <Accordion data-size={12} onClick={() => navigate('/config/project?id='+project.projectId)} key={project.projectId} title={project.name}>
                                                </Accordion>
                                            )
                                    })}
                               </Grid>
                        </Widget>
                        <Widget key={"users"}
                                    data-size={12}
                                    widgetStyle={WidgetStyles.Normal}
                                    title={"All Users"}
                                    className={""}
                                    topLayout={""}
                                    top={null}
                                    fit={false}
                                    loading={loading}
                            >
                                <Grid gap={10} columns={4} key={`${users.length}_${loading}`}>
                                    {users && users.map(user => {

                                            return (
                                                <Accordion data-size={3} onClick={() => navigate('/config/user?email='+user.email)} key={user.email} title={user.firstName + " "+user.lastName}>
                                                </Accordion>
                                            )
                                    })}
                               </Grid>
                        </Widget>
                        <Widget key={"usersJson"} data-size={6}  >
                        <textarea readOnly rows={30} style={{width:"100%"}} defaultValue={JSON.stringify(users, null, 2)}></textarea>
                        </Widget>
                        <Widget key={"clientsJson"} data-size={6}>
                        <textarea readOnly rows={30} style={{width:"100%"}} defaultValue={JSON.stringify(clients, null, 2)}></textarea>
                        </Widget>
                        <Widget key={"projectsJson"} data-size={6}  >
                        <textarea readOnly rows={30} style={{width:"100%"}} defaultValue={JSON.stringify(projects, null, 2)}></textarea>
                        </Widget>


                    </Grid>

                </main>
            </div>
        );
    }


    public onRenderTop() {

        return (
           <div style={{gap:20, display:"flex", flexWrap:"wrap", marginRight:"20px"}}>
            <Button text='Save Config' style={ButtonStyle.primary} to={"/config"} icon={<IconSave/>}/>
           </div>
        )
    }


public getUserRender(user: ScUser): any {
    const {stUsers} = this.state;
    const {onFieldChange} = this;
    return (
        <>
            <SettingField
                slug={"Id"}
                title={"ID"}
                readOnly
                value={user.email}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"email"}
                title={"Email"}
                value={user.email}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"firstName"}
                title={"First Name"}
                value={user.firstName}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                 slug={"lastName"}
                 title={"Last Name"}
                 value={user.lastName}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"mobile"}
                title={"Mobile"}
                value={user.mobile}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"position"}
                title={"Position Title"}
                value={user.position}
                type={FieldType.Text}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"gender"}
                title={"Gender"}
                value={user.gender}
                type={FieldType.ToggleSelect}
                options={[
                    {key: "Male", icon: <IconMonitor />},
                    {key: "Female", icon: <IconMonitor />},
                    {key: "Other", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"team"}
                title={"Team"}
                value={user.team ? user.team : ""}
                type={FieldType.ToggleSelect}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"px"}
                title={"Pixels"}
                value={20}
                type={FieldType.Number}
                suffix={"px"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"hours"}
                title={"Hours"}
                value={40}
                type={FieldType.Number}
                prefix={"🕐"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"hourly"}
                title={"Hourly rate"}
                value={52}
                type={FieldType.Number}
                prefix={"$"}
                suffix={".00"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"select"}
                title={"Select"}
                placeholder={"Choose an option"}
                value={user.team ? user.team : ""}
                type={FieldType.Select}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"multiSelect"}
                title={"Multi Select"}
                placeholder={"Choose some options"}
                value={[]}
                type={FieldType.MultiSelect}
                options={[
                    {key: "red", text:"Red", icon: <IconMonitor />},
                    {key: "blue", text:"Blue", icon: <IconMonitor />},
                    {key: "", text:"None", icon: <IconMonitor />},
                ]}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"toggle"}
                title={"Toggle"}
                value={false}
                type={FieldType.Boolean}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"hireDate"}
                title={"Date hired"}
                value={"2022-10-01"}
                type={FieldType.Date}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"image"}
                title={"Image"}
                value={user.profile_image}
                type={FieldType.Image}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

            <SettingField
                slug={"streamtime_id"}
                title={"Streamtime User"}
                value={user.streamtime_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"employeehero_id"}
                title={"Employee Hero User"}
                value={user.employeehero_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"upwork_id"}
                title={"Upwork User"}
                value={user.upwork_id}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />
            <SettingField
                slug={"authId"}
                title={"oAuth User"}
                value={user.authId}
                placeholder={"Choose a user to link"}
                type={FieldType.SearchSelect}
                options={stUsers.map(stUser => {
                    return {key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}
                })}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => onFieldChange('user', user.email, slug, newVal)}
            />

        </>
    )
}
public onFieldChange(type: 'user' | 'client' | 'project', identity:string, fieldSlug:string, newValue:string): any {
}


}






/**
 * Scout ST Service
 */

 import { cache, cached, call, EP, sort } from "./API";

 /** API */

 export const getSTUsers = async (): Promise<any[]>  => {
     const data = await call("GET", EP.getSTUsers, "stUsers");
     if(!data)return [];
     let stUsers = data.stUsers;
     stUsers = sort(stUsers, "firstName");

     return stUsers;
 }

 export const getSTClients = async (): Promise<any[]>  => {
  const data = await call("GET", EP.getSTClients, "stClients");
  if(!data)return [];
  let stClients = data.stClients;
  // stUsers = sort(stUsers, "firstName");

  return stClients;
}

 export const getSTProjects = async (): Promise<any[]>  => {
  const data = await call("GET", EP.getSTProjects, "stProjects");
  if(!data)return [];
  let stProjects = data.stProjects;
  // stUsers = sort(stUsers, "firstName");

  return stProjects;
}

 export const getEHUsers = async (): Promise<any[]>  => {
  const data = await call("GET", EP.getEHUsers, "ehUsers");
  if(!data)return [];
  let ehUsers = data.ehUsers;
  ehUsers = sort(ehUsers, "firstName");

  return ehUsers;
}



 /** Static */






 /** Utils */

import { ReactComponent as IconBuild } from '~/assets/icons/build-ico.svg';
import { ReactComponent as IconAdd } from '~/assets/icons/addfile-ico.svg';
import { ReactComponent as IconDelete } from '~/assets/icons/delete-ico.svg';
import { User } from '@auth0/auth0-react';
import { IWidgetProps, WidgetStyles } from '../components/core/widget/Widget';
import  {  ScWidgetContext } from '../components/widgets/BaseWidget';
import { NavigateFunction } from 'react-router-dom';


export enum Team {
  Red,
  Blue,
  User,
  Both,

}
export const ETeamByName = (name:string) => {
  return name === "blue" ? Team.Blue : Team.Red;
}

export const ETeams = () => {
  return {
    0: 'Red',
    1: 'Blue'
  };
};
export const ETeamFull = () => {
  return {
    0: 'Red Team',
    1: 'Blue Team'
  };
};

export enum TimeFrequency {
  Monthly,
  Weekly,
  Today,
  Daily,
}

export const ETimeFrequencies = () => {
  return {
    0: 'Monthly',
    1: 'Weekly',
    2: 'Today'
  };
};

export enum TimeType {
  Teams,
  User
}

export const ETimeTypes = () => {
  return {
    0: 'Teams',
    1: 'User'
  };
};

export enum ActivityType {
  Commit,
  Add,
  Delete
}

export enum ActivitySource {
    Any,
    Git,
    Files,
}
export const EActivitySource = () => {
    return {
      0: 'All',
      1: 'Git Commits',
      2: 'File Changes'
    };
};

export interface ScActivity {
  source: ActivitySource;
  type: ActivityType;
  title: string;
  message: string;
  date: string;
  more?: any;
}


export const ActivityIcon = (type:ActivityType) => {
    switch(type){
        case ActivityType.Commit:
            return <IconBuild/>;
        case ActivityType.Delete:
            return <IconDelete/>;
        case ActivityType.Add:
        default:
            return <IconAdd/>;
    }
}


export interface ScNav {

}

export interface ScNavItem {
  name: string,
  link: string,
  icon: any
}



export interface ScUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  position: string;
  team: string;
  gender: string;
  profile_image: string;
  mobile: string;
  streamtime_id: string;
  employeehero_id: string;
  bitbucket_id: string;
  upwork_id: string;
  authId: string;

  //Perms
  userGroupId: string;
  userPermissions: string[];


  //Logged In
  authUser?: User;
}

export interface ScProject {
  projectId: string;
  clientId: string;
  streamTimeId: string;
  name: string;
  startDate?: string;
  endDate?: string;
  budget: string;
  users: string[];

  bitbucket_project_id?:string;
}

export interface ScClient {
  clientId: string;
  name: string;
  streamTimeId: string;
  bitbucket_id: string;
}

export interface ScJob {
  projectId: string;
  jobId: string;
  name: string;
  plannedTimeMinutes: number;
  loggedTimeMinutes: number;
  startDate: string;
  endDate: string;
}

export interface ScTime {
  timeId: string;
  cost: number,
  totalExTax: number,
  loggedTime: string,
  startDate: string,
  endDate: string,
  minutes: number,
  projectId: string,
  team: string,
  type: string,
  completedDatetime?: string,
  entries?: number,
}

export interface ScUserTime {
  userEmail: string,
  userStId: string,
  times: ScTime[],
}

export interface ScCost {
  label: string;
  people: number;
  cost: number;
}

export interface ScSearchable {
  type: "client" | "project" | "user" | "job" ,
  data: ScClient | ScProject | ScUser | ScJob ,
  keywords: string,
}


export interface ScWidget extends IWidgetProps{
  slug: string;
  context?: ScWidgetContext;
}

export interface IAppContext{
  timeUpdate: string;
  page: ScPage;
  client: ScClient;
  project: ScProject;
  user: ScUser;
  clients: ScClient[];
  projects: ScProject[];
  searchParams: URLSearchParams;
  navigate: NavigateFunction;
}

export interface IPageContext{
  appContext: IAppContext;
  client: ScClient;
  project: ScProject;
  page: ScPage;
  timeFrequency: TimeFrequency,
}

export interface IWidgetData {
  slug: string;
  size?: number;
  match?: boolean;
  widgetStyle?:WidgetStyles;
  data?: any;
}

export interface IWidgetContext{
  appContext: IAppContext;
  pageContext: IPageContext;

  page: ScPage;
  client: ScClient;
  project: ScProject;
  timeFrequency: TimeFrequency;
  size?: number;
  match?: boolean;
  widgetStyle?:WidgetStyles;
  data?: any;
}


export type ScWidgets = {
  // [key: string]: typeof BaseWidget<ScWidget, BaseWidgetState>;
  [key: string]: any;
}

export type ScPages = {
  // [key: string]: typeof BasePage<ScPage, BasePageState>;
  [key: string]: any;
}

export interface ScPage {
  title: string,
  icon?: any,
  top?: () => any,
  slug: string,
  columns: number,
  widgets: IWidgetData[],
  context?: ScWidgetContext;
}









export interface BbResponse {
  page: number;
  pagelen: number;
  size: number;
  values: any;
}

export interface BbLink {
  href: string;
}

export interface BbWorkspace {
  links: {
    avatar: BbLink,
    html: BbLink,
    self: BbLink
  },
  name: string;
  slug: string;
  type: string;
  uuid: string;
}

export interface BbTeam {
  links: {
    avatar: BbLink,
    html: BbLink,
    self: BbLink
  },
  display_name: string;
  username: string;
  type: string;
  uuid: string;
}

export interface BbProject {
  links: {
    avatar: BbLink,
    html: BbLink,
    repositories: BbLink,
    self: BbLink
  },
  name: string;
  description: string;
  key: string;
  type: string;
  uuid: string;
  workspace: BbWorkspace;
  owner: BbTeam;

  is_private:boolean;
  has_publicly_visible_repos:boolean;
  created_on:string;
  updated_on:string;

}

export interface BbRepo {
  links: {
    avatar: BbLink,
    html: BbLink,
    self: BbLink,
    repositories: BbLink,
    branches: BbLink,
    commits: BbLink,
    downloads: BbLink,
    forks: BbLink,
    hooks: BbLink,
    pullrequests: BbLink,
    source: BbLink,
    tags: BbLink,
    watchers: BbLink,
    clone: {
      name: string;
      href: string;
    }[],
  },
  full_name: string;
  name: string;
  description: string;
  slug: string;
  type: string;
  uuid: string;

  is_private:boolean;
  has_issues:boolean;
  has_wiki:boolean;
  created_on:string;
  updated_on:string;

  project: BbProject;
  workspace: BbWorkspace;
  owner: BbTeam;

  website:string;
  scm:string;
  size:number;
  fork_policy:string;
  language:string;
  mainbranch:{
    name:string;
    type:string;
  };
  override_settings:{
    branching_model:boolean;
    default_merge_strategy:boolean;
  };
}

export interface BbUser {
  type:string;
  account_id:string;
  uuid:string;
  nickname:string;
  display_name:string;
  links: {
    avatar: BbLink,
    html: BbLink,
    self: BbLink
  };
}

export interface BbAuthor {
  raw:string;
  type:string;
  user: BbUser;
}

export interface BbCommit {
  type:string;
  date:string;
  hash:string;
  message:string;
  author: BbAuthor;
  parents: BbCommit[];
  rendered: {
    message: {
      html:string;
      markup:string;
      raw:string;
      type:string;
    }
  },
  repository: BbRepo,
  summary: {
    html:string;
    markup:string;
    raw:string;
    type:string;
  },

  links: {
    approve: BbLink,
    comments: BbLink,
    diff: BbLink,
    html: BbLink,
    patch: BbLink,
    self: BbLink,
    statuses: BbLink,
  }

}

export interface ScCommit {
  date:string;
  id:string;
  message:string;
  user: ScUser;
  userId: string;
  team: Team;
  repoId: string;
  // projectId: string;

  getRepo: () => BbRepo;
}

export interface ScCommitGroup {
  commits: ScCommit[];
  dateStart: string;
  dateEnd: string;
  count: number;
}

export type ScCommitGroups = {
  [key: string]: ScCommitGroup;
}




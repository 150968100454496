import moment from 'moment';
import { TimeFrequency } from './Types';



export default class Util {

  public static frequentData = {}

  public static generateLineChartData(labels:any[], sets:any[]): any {
    return {
      data: {
        defaultFontFamily: 'Montserrat',
        labels: labels,
        datasets: sets
      },

      options: {
        legend: true,
        tooltips: {
          intersect: false
        },
        hover: {
          // mode: "nearest",
          intersect: true
        },
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              stepSize: 20,
              padding: 10
            }
          },

          x: {
            ticks: {
              padding: 5
            }
          }
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    };
  }

  public static getCostsFromTimes(times: any, max: number, frequency: TimeFrequency): any {
    let data: any = [];
    let timesForChart = [...times];
    if (timesForChart.length) {
      timesForChart = timesForChart.splice(-max);
    }
    timesForChart.reverse();

    data = timesForChart.map((time, index) => {
      if (index > max) return time;
      let label = 'NA';
      if (frequency == TimeFrequency.Monthly) {
        label = Util.getMonthFromDate(time.startDate, true);
      } else if (frequency == TimeFrequency.Weekly) {
        label = Util.getWeeksAgo(time.startDate);
      } else if (frequency == TimeFrequency.Today) {
        label = moment(time.loggedTime).format('h:00 A');
      }


      return {
        //TODO change entries to people
        people: time.entries ? time.entries : 0,
        label: label,
        cost: Math.round(time.cost)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      };
    });

    return data;
  }

  public static getAgo(date: any, unit:moment.unitOfTime.Diff) {
    const timeDate = moment(date);
    return moment().diff(timeDate, unit);
  }

  public static getMonthsAgo(date: any) {
    const ago = this.getAgo(date, "months");
    return ago == 0 ? 'This month': ago == 1 ? 'Last month' : `${ago} month's ago`;
  }

  public static getWeeksAgo(date: any) {
    const ago = this.getAgo(date, "weeks");
    return ago == 0 ? 'This week': ago == 1 ? 'Last week' : `${ago} week's ago`;
  }

  public static getDaysAgo(date: any) {
    const ago = this.getAgo(date, "days");
    return ago == 0 ? 'Today': ago == 1 ? 'Yesterday' : `${ago} day's ago`;
  }

  public static getHoursAgo(date: any) {
    const ago = this.getAgo(date, "hours");
    return ago == 0 ? 'Now': ago == 1 ?  `1 hour ago` : `${ago} hour's ago`;
  }

  public static getUserTeam(user: any) {
    if (!user.team) return 'Red Team';
    if (user.team == 'red') return 'Red Team';
    if (user.team == 'blue') return 'Blue Team';
  }

  public static getTimeFrequencyAsArray() {
    return {
      0: 'Monthly',
      1: 'Weekly',
      2: 'Today'
    };
  }

  public static sortUsersByTimeLogged(users: any, times: any) {
    return users.sort((a: any, b: any) => {
      let userTimeA = times.filter((time: any) => {
        return time.groupBy == a.streamtime_id;
      });
      let userTimeB = times.filter((time: any) => {
        return time.groupBy == b.streamtime_id;
      });

      if (!userTimeA.length) {
        userTimeA = 0;
      } else {
        userTimeA = parseInt(userTimeA[0].minutes);
      }
      if (!userTimeB.length) {
        userTimeB = 0;
      } else {
        userTimeB = parseInt(userTimeB[0].minutes);
      }
      if (userTimeA < userTimeB) {
        return 1;
      }
      if (userTimeA > userTimeB) {
        return -1;
      }
      return 0;
    });
  }

  public static getSTTimeLoggedByUser(times: any, userSTId: any) {
    if (!times.length) return null;

    let counter = 0;
    times.map((time: any) => {
      if (time.groupBy == userSTId) {
        counter += parseInt(time.minutes);
      }
    });

    return counter;
  }

  public static getMonthsBehind(max = 6){
    if(this.frequentData[`monthsBehind${max}`])return this.frequentData[`monthsBehind${max}`];

    const months = Util.getMonthArray(true);
    const currentMonth = moment().format("MMMM");
    const currentMonthIndex = months.indexOf(currentMonth);
    const labels = [];

    for(let i = 1; i < max+1; i++){
      let index = currentMonthIndex - max + i;
      index = index < 0  ? index+12 : index;
      const month = months[index];
      labels.push(month);
    }
    this.frequentData[`monthsBehind${max}`] = labels;
    return labels;
  }

  public static getWeeksBehind(max = 6){
    if(this.frequentData[`weeksBehind${max}`])return this.frequentData[`weeksBehind${max}`];

    const currentWeek = moment().startOf("week");

    let labels = [
      currentWeek.format("YYYY-MM-DD HH:mm:ss")
    ];

    for(let i = 1; i < max; i++){
      let week = moment(currentWeek.format("YYYY-MM-DD HH:mm:ss")).subtract(i, "week").startOf("week");
      labels.push(week.format("YYYY-MM-DD HH:mm:ss"));
    }
    labels = labels.reverse();
    this.frequentData[`weeksBehind${max}`] = labels;
    return labels;
  }

  public static getDaysBehind(max = 6){
    if(this.frequentData[`daysBehind${max}`])return this.frequentData[`daysBehind${max}`];

    const currentDay = moment().startOf("day");

    let labels = [
      currentDay.format("YYYY-MM-DD HH:mm:ss")
    ];

    for(let i = 1; i < max; i++){
      let week = moment(currentDay.format("YYYY-MM-DD HH:mm:ss")).subtract(i, "day").startOf("day");
      labels.push(week.format("YYYY-MM-DD HH:mm:ss"));
    }

    labels = labels.reverse();
    this.frequentData[`daysBehind${max}`] = labels;
    return labels;
  }

  public static getHoursBehind(max = 6){
    if(this.frequentData[`hoursBehind${max}`])return this.frequentData[`hoursBehind${max}`];

    const currentHour = moment().startOf("hour");

    let labels = [
      currentHour.format("YYYY-MM-DD HH:mm:ss")
    ];

    for(let i = 1; i < max; i++){
      let week = moment(currentHour.format("YYYY-MM-DD HH:mm:ss")).subtract(i, "hour").startOf("hour");
      labels.push(week.format("YYYY-MM-DD HH:mm:ss"));
    }

    labels = labels.reverse();
    this.frequentData[`hoursBehind${max}`] = labels;
    return labels;
  }

  public static getSTTimeLoggedDailyAverageByUser(userIndividualTimes: any, userSTId: any) {
    if (!userIndividualTimes.length) return null;

    let findUserTimes: any = null;

    userIndividualTimes.map((userTime: any) => {
      if (userTime.userStId == userSTId) {
        findUserTimes = userTime.times;
      }
    });

    if (!findUserTimes) return 0;
    if (findUserTimes.length == 0) return 0;

    let counter = 0;
    findUserTimes.map((time: any) => {
      counter += parseInt(time.minutes);
    });

    //this will need to be grouped to days first otherwise it wont be accurate, some loggs on the same day
    return counter / findUserTimes.length;
  }

  public static getSTLastLoggedTimeByUser(userIndividualTimes: any, userSTId: any) {
    if (!userIndividualTimes.length) return null;

    let findUserTimes: any = null;

    userIndividualTimes.map((userTime: any) => {
      if (userTime.userStId == userSTId) {
        findUserTimes = userTime.times;
      }
    });

    if (!findUserTimes) return null;
    if (findUserTimes.length == 0) return null;

    let last = findUserTimes[findUserTimes.length - 1];

    //this will need to be grouped to days first otherwise it wont be accurate, some loggs on the same day
    return last.completedDatetime;
  }

  public static getTimeAgo(dateString: string): string {
    return moment(dateString).fromNow(true);
  }

  public static getFormattedFromMins(mins: any, full = false) {
    let hours = parseInt(moment().startOf('day').add(mins, 'minutes').format('h'));

    return hours > 1 ? hours + (full ? ' Hours' : ' hrs') : hours + (full ? ' Hour' : ' hr');

    let minsS = full ? ' Minute' : 'm';
    let hoursS = full ? ' Hour' : 'h';
    let daysS = full ? ' Day' : 'd';

    if (mins == '0') return '0 ' + mins;
    if (mins == null) return '0 ' + mins;

    mins = Number(mins);

    //change to seconds
    let time = mins * 60;

    const d = Math.floor(time / (3600 * 24));
    const h = Math.floor((time % (3600 * 24)) / 3600);
    const m = Math.floor((time % 3600) / 60);

    const dDisplay = d > 0 ? d + daysS + (d > 1 ? 's' : '') + ' ' : '';
    const hDisplay = h > 0 ? h + hoursS + (h > 1 ? 's' : '') + ' ' : '';
    const mDisplay = m > 0 ? m + minsS + (m > 1 ? 's' : '') + ' ' : '';
    return dDisplay + hDisplay + mDisplay;
  }

  public static getMonthArray(full = false) {
    if (full)
      return [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }

  public static getMonthFromDate(date: string, full = false) {
    const months = this.getMonthArray(full);
    let dateSplit = date.split('-');
    return months[parseInt(dateSplit[1])];
  }

  public static generateBarChartData(data: any) {
    let datasets: any[] = [];
    Object.keys(data.sets).map((setKey: string) => {
      const setData = data.sets[setKey];
      if (!setData.data || setData.data.length == 0) return;

      switch (setData.type) {
        case 'Red':
          datasets.push({
            label: 'Red Team',
            data: setData.data,
            borderColor: '#E52121',
            borderWidth: '0',
            backgroundColor: '#E52121',
            barThickness: 24
          });
          break;
        case 'Blue':
          datasets.push({
            label: 'Blue Team',
            data: setData.data,
            borderColor: '#2C5EAA',
            borderWidth: '0',
            backgroundColor: '#2C5EAA',
            barThickness: 24
          });
          break;
        case 'User':
          datasets.push({
            label: 'User',
            data: setData.data,
            borderColor: '#1A202D',
            borderWidth: '0',
            backgroundColor: '#1A202D',
            barThickness: 24
          });
          break;
      }
    });

    return {
      data: {
        defaultFontFamily: 'Montserrat',
        labels: data.labels,
        datasets: datasets
      },
      options: {
        plugins: {},
        scales: {
          y: {
            ticks: { beginAtZero: true }
          },
          x: { barPercentage: 0.5 }
        }
      }
    };
  }
}



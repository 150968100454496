import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import './CapacityUsage.scss';

interface ICapacityUsageProps {}

export default function CapacityUsage(props: ICapacityUsageProps) {
  // const {title, widgetStyle} = props;

  return (
    <>
     {/* @ts-ignore*/}
     <Chart options={getChartOptions()} series={getChartData()} type="radialBar" width={'100%'} height={'auto'} />
    </>
  );
}

const getChartOptions = (): ApexOptions => {
  return {
    chart: {
      events: {
        click: (e, chart, opt) => {
        }
      }
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } }
    },
    plotOptions: {
      radialBar: {
        offsetY: -30,
        hollow: {
          margin: 10,
          background: '#FFBEBC',
          size: '50px'
        },
        track: {
          background: '#FFBEBC',
          strokeWidth: '100%',
          margin: 15,
          opacity: 1
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            fontFamily: 'Montserrat',
            fontSize: '38px',
            fontWeight: 700,
            color: '#CB9A99'
          },
          total: {
            show: false
          }
        }
      }
    },
    colors: ['#FC2121'],
    fill: {
      colors: ['#FC2121']
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Capacity Usage'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '12px',
      floating: true,
      markers: {
        width: 18,
        height: 5,
        radius: 4
      },
      itemMargin: {
        vertical: 5,
        horizontal: 10
      },
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    }
  };
};

const getChartData = () => {
  return [32];
};

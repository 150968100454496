import axios, {AxiosInstance} from "axios";
import { notifyError } from "./ToastService";

export const SCOUT_BASE_URL = "https://5xndgk07i2.execute-api.us-west-1.amazonaws.com/Dev";
export const SCOUT_KEY = 'NOj3LwRbOVoslm3YCYVA6hEltOxDKk54HlCpuFQb';//TODO store in env vars

export const EP = {
	getSTUsers: "st/users",
	getSTClients: "st/clients",
	getSTProjects: "st/projects",
	getEHUsers: "eh/users",
	getAuthUsers: "sc/auth_users",
	getUsers: "sc/users",
	updateUserBy: {
		email: "sc/users?email={email}",
  },
	updateClientBy: {
		clientId: "sc/clients?clientId={clientId}",
  },
  updateProjectBy: {
		projectId: "sc/projects?projectId={projectId}",
  },
	getUserBy: {
		email: "sc/users/find-by-email?email={email}",
		bbId: "sc/users/find-by-email?email={bbId}",
	},
	getProjects: "sc/projects",
	getClients: "sc/clients",
	getJobs: "sc/jobs",
	getJobsBy: {
		project: "sc/jobs?project={project}"
	},
	getTimesBy: {
		projectId: "sc/time/projects?project={projectId}"
	}
}



export const API:AxiosInstance =  axios.create({
  baseURL: SCOUT_BASE_URL,
  headers: {
    'x-api-key': SCOUT_KEY,
	'accept': '*/*',
  }
});

export const fill = (path:string, props:string[][]) => {
	let pathFilled = path;
	if(!path)return "";
	props.map(prop => {
		pathFilled = pathFilled.replaceAll(`{${prop[0]}}`, prop[1]);
	});
	return pathFilled;
}

export const sort = (array: any[], key:string) => {
	return array.sort( ( a:any, b:any ) => {
		const aProp = a[key], bProp = b[key];
		return  aProp < bProp ? -1 : aProp > bProp ? 1 : 0;
    });
}

export const filter = (array: any[], property:string, value:any) => {
    return [...array].filter((item: any) => {
        return item[property] == value;
    });
}

export const searchFilter = (array: any[], property:string, value:any) => {
    return [...array].filter((item: any) => {
        return item[property].toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
}

export const find = (array: any[], property:string, value:any) => {
    return array.find((item: any) => {
        return item[property] == value;
    });
}

const type = (method:string): any => {
	const types = {
		"GET": API.get,
		"POST": API.post,
	}

	return types[method] ? types[method] : API.get;
}

export const call = async (method:string, path:string, cacheKey?:string) => {
	if(cacheKey && cached(cacheKey)) return cached(cacheKey);
	const response = await type(method)(path);
	if(response.status !== 200){
		notifyError(`API Error '${cacheKey}' [{${method}} ${path}]`, response);

		return null;
	}

	const data = await response.data.data;
	if(cacheKey) return cache(cacheKey, data);

	return data;
}

export const post = async (path:string, props:any) => {
	const response = await API.post(path, props);
	if(response.status !== 200){
		notifyError(`API Error [{POST} ${path}]`, response);

		return null;
	}

	const data = await response.data.data;
	return data;
}

export const cache = (key:string, data:any) => {
	let cache = window["scout_cache"];

	if(!cache)cache = window["scout_cache"] = {};
	let cacheItem = cache[key] = data;

	let cacheCounts = cache["_counts"];
	if(!cacheCounts)cacheCounts = cache["_counts"] = {};
	if(cacheCounts[key] == undefined){
		cacheCounts[key] = 1;
	}
	return cacheItem;
}

export const cached = (key:string) => {
	let cache = window["scout_cache"];
	if(!cache)return false;
	let cacheItem = cache[key];
	if(!cacheItem)return false;

	let cacheCounts = cache["_counts"];
	if(!cacheCounts)cacheCounts = cache["_counts"] = {};
	if(cacheCounts[key] != undefined){
		cacheCounts[key] += 1;
	}

	return cacheItem;
}

export const urlQuery = () => {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}



import { ScJob } from '~/app/services/Types';
import Util from '../../../services/Util';

interface IProps {
    job: ScJob;
}

export default function JobCard(props:IProps) {
    const job:ScJob = props.job;
    let percentTimeUsed:any = job.plannedTimeMinutes !== 0 ? (" ("+( Math.round(job.plannedTimeMinutes - job.loggedTimeMinutes) / job.plannedTimeMinutes *100, 2)+"%)") : "";



    return <div key={job.jobId} className="jobCard">
        <h5>Job</h5>
        <p>Name: {job.name}</p>
        <p>Start date: {job.startDate}</p>
        <p>End date: {job.endDate}</p>
        <p>Time: {Util.getFormattedFromMins(job.loggedTimeMinutes)} {job.plannedTimeMinutes === 0 ? "" : ("out of "+Util.getFormattedFromMins(job.plannedTimeMinutes)+ percentTimeUsed)}</p>
    </div>


}

import { ScClient } from '~/app/services/Types';

interface IProps {
    client: ScClient;
}

export default function ClientCard(props:IProps) {
    const client = props.client;
    
    
    return <div className="jobCard">
        <h5>Client</h5>
        <p>Name: {client.name}</p>
       
        </div>


}
/**
 * Scout Client Service
 */

import axios, { AxiosInstance } from "axios";
import moment from "moment";
import { cache, cached, call, EP, fill, filter, find, sort } from "./API";
import { findClientBy } from "./ClientService";
import { BbCommit, BbProject, BbRepo, BbResponse, BbUser, BbWorkspace, ETeamByName, ETeams, ScClient, ScCommit, ScCommitGroup, ScCommitGroups, ScProject, ScUser, Team, TimeFrequency } from "./Types";
import { getUserBy } from "./UserService";

/** API */

export const BB_BASE_URL = "https://api.bitbucket.org/2.0/";

//Auth code
export const BB_KEY = 'Q9usq52uh2vzePvBuB';//TODO store in env vars
export const BB_CODE = 'tmXRBd7XaRN64DANX6';//TODO store in env vars
export const BB_SECRET = '7JB8tsfsadzy9QweETYnvXG5Eva59Egg';//TODO store in env vars

export const BB_EP = {
	getWorkspaces: "workspaces",
	getProjectsBy: {
		workspace: "workspaces/{workspace}/projects"
	},
  getUsersBy: {
		workspace: "workspaces/{workspace}/members"
	},
    getReposBy: {
		project: `repositories/{workspace}?q=project.key="{project}"`
	},
    getCommitsBy: {
		repo: `repositories/{workspace}/{repo}/commits`
	},

	getUserBy: {
		email: "sc/users/find-by-email?email={email}"
	}
}

const updateToken = (time, data) => {
    const cacheKey = "BB_TOKEN";
    const token = data.access_token;
    const expires_in = data.expires_in;
    const refresh_token = data.refresh_token;
    const expiresAt =  time + expires_in;
    const adjustedDateObj = new Date(expiresAt);

    cache(cacheKey, token);
    cache(cacheKey+"_expires_in", expires_in);
    cache(cacheKey+"_expires_at", expiresAt);
    cache(cacheKey+"_refresh_token", refresh_token);

    return token;
}

const getBBTokenByRefresh = async (refresh_token:string) => {
    const currentTime = Date.now();

    const qs = require('qs');
    const data = qs.stringify({
    'grant_type': 'refresh_token',
    'refresh_token': refresh_token,
    });
    const response = await axios({
        method: 'post',
        url: 'https://bitbucket.org/site/oauth2/access_token',
        auth: {
            username: BB_KEY,
            password: BB_SECRET
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data : data
    });
    const token = updateToken(currentTime, response.data);


	return token;
}

const getBBToken = async () => {
    //Expires after 1 hour
    const currentTime = Date.now();

    const cacheKey = "BB_TOKEN";
    if(cached(cacheKey+"_expires_at")){
       const expiresAt = cached(cacheKey+"_expires_at");
       if(expiresAt-currentTime < 1000){
            cache(cacheKey, undefined);
            const refresh_key = cached(cacheKey+"_refresh_token");
            return await getBBTokenByRefresh(refresh_key);
       }
    }

    if(cacheKey && cached(cacheKey)) return cached(cacheKey);

    const qs = require('qs');
    const data = qs.stringify({
    'grant_type': 'client_credentials'
    });
    const response = await axios({
        method: 'post',
        url: 'https://bitbucket.org/site/oauth2/access_token',
        auth: {
            username: BB_KEY,
            password: BB_SECRET
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data : data
    });
    const token = updateToken(currentTime, response.data);
	return token;
}




export const bbCall = async (method:string, path:string, cacheKey?:string): Promise<BbResponse> => {
	if(cacheKey && cached(cacheKey)) return cached(cacheKey);
    const token = await getBBToken();


    const response = await axios({
        method: method,
        url: `${BB_BASE_URL}${path}`,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
    });

	if(response.status !== 200){
		return null;
	}

	const data:BbResponse = await response.data;
	if(cacheKey) return cache(cacheKey, data);

	return data;
}

export const getBBWorkspaces = async (): Promise<BbWorkspace[]>  => {

    const data:BbResponse = await bbCall("GET", BB_EP.getWorkspaces, "workspaces");
    const moreThanOnePage = data.size > data.pagelen;


    return data.values;
}

export const getBBProjects = async (workspaceSlug:string): Promise<BbProject[]>  => {

    const data:BbResponse = await bbCall("GET", fill(BB_EP.getProjectsBy.workspace, [["workspace", workspaceSlug]]), `workspace_${workspaceSlug}_projects`);
    const moreThanOnePage = data.size > data.pagelen;

    return data.values;
}

export const getBBRepos = async (workspaceSlug:string, projectKey:string): Promise<BbRepo[]>  => {

    const data:BbResponse = await bbCall("GET", fill(BB_EP.getReposBy.project, [["workspace", workspaceSlug], ["project", projectKey]]), `project_${projectKey}_repos`);
    const moreThanOnePage = data.size > data.pagelen;

    return data.values;
}

export const getBBUsers = async (workspaceSlug:string): Promise<BbUser[]>  => {

    const data:BbResponse = await bbCall("GET", fill(BB_EP.getUsersBy.workspace, [["workspace", workspaceSlug]]), `bb_${workspaceSlug}_users`);
    const moreThanOnePage = data.size > data.pagelen;

    return data.values.map(membership=> {
      return membership.user
    });
}

export const getBBCommits = async (workspaceSlug:string, repoSlug:string): Promise<BbCommit[]>  => {

    const data:BbResponse = await bbCall("GET", fill(BB_EP.getCommitsBy.repo, [["workspace", workspaceSlug], ["repo", repoSlug]]), `repo_${repoSlug}_commits`);
    const moreThanOnePage = data.size > data.pagelen;

    return data.values;
}

export const loadByClient = async (client:ScClient): Promise<any>  => {

    // const workspaces = await getBBWorkspaces();
    const projects = await getBBProjects("hide-and-seek");
    const repos = await getBBRepos("hide-and-seek", "RSG");
    const commits = await getBBCommits("hide-and-seek", "rsg-storyblok");


}

//TODO from database
export const getBBOrganisationByClientId = async (clientId:string): Promise<string>  => {
    return (await findClientBy("clientId", clientId)).bitbucket_id;
}

export const getBBProjectsByProject = async (project:ScProject): Promise<BbProject[]>  => {
    const bbOrgName = await getBBOrganisationByClientId(project.clientId);
    if(!bbOrgName || !project.bitbucket_project_id)return null;
    const projects = await getBBProjects(bbOrgName);
    const bbProject = find(projects, "key", project.bitbucket_project_id);
    return [bbProject];
}

export const getBBReposByClient = async (client:ScClient): Promise<BbRepo[]>   => {
    const bbOrgName = await getBBOrganisationByClientId(client.clientId);
    if(!bbOrgName)return null;

    const projects:BbProject[] = await getBBProjects(bbOrgName);
    const repos:BbRepo[] = [];
    for(let project of projects){
        const projectRepos = await getBBRepos(bbOrgName, project.key);

        repos.push(...projectRepos)
    }

    return repos;
}

//TODO get from database
export const getBBReposByProject = async (project:ScProject): Promise<BbRepo[]>  => {
    const bbOrgName = await getBBOrganisationByClientId(project.clientId);
    if(!bbOrgName || !project.bitbucket_project_id)return null;
    const repos = await getBBRepos(bbOrgName, project.bitbucket_project_id);
    return repos;
}

export const getBBCommitsByClient = async (client:ScClient, project:ScProject): Promise<BbCommit[]>  => {
    const bbOrgName = await getBBOrganisationByClientId(client.clientId);
    if(!bbOrgName)return null;

    const repos:BbRepo[] = await getBBReposByClient(client);
    const commits:BbCommit[] = [];
    for(let repo of repos){
        const projectCommits:BbCommit[] = await getBBCommits(bbOrgName, repo.slug);

        commits.push(...projectCommits)
    }

    return commits;
}

export const getBBCommitsByProject = async (project:ScProject): Promise<BbCommit[]>  => {
    const bbOrgName = await getBBOrganisationByClientId(project.clientId);
    if(!bbOrgName || !project.bitbucket_project_id)return null;
    const repos:BbRepo[] = await getBBReposByProject(project);
    const commits:BbCommit[] = [];
    for(let repo of repos){
        const projectCommits:BbCommit[] = await getBBCommits(bbOrgName, repo.slug);

        commits.push(...projectCommits)
    }

    return commits;
}




/** Static */
export const convertCommits = async (commits:BbCommit[]): Promise<ScCommit[]>  => {
    let scCommits:ScCommit[] = [];
    await Promise.all(commits.map( async (commit:BbCommit) => {
      if(!commit.author || !commit.author.user)return;
        const user:ScUser = await getUserBy("bitbucket_id", commit.author.user.account_id);

        scCommits.push({
            getRepo: () => commit.repository,
            date: commit.date,
            id: commit.hash,
            message: commit.message,
            user: user,
            team: user ? ETeamByName(user.team): Team.Red,
            repoId:  commit.repository.uuid,
            userId: commit.author.user.account_id
        });
    }))

    return scCommits;

}

export const filterCommitsByTeam = (commits:ScCommit[], team: Team): ScCommit[]  => {
    return filter(commits, "team", team);
}

export const filterCommitsByUser = (commits:ScCommit[], user: ScUser): ScCommit[]  => {
    if(!user || !user.email)return [];
    return [...commits].filter((commit: ScCommit) => {
        if(!commit || !commit.user)return false;
        return commit.user.email === user.email;
    });
}


export const groupCommitsBy = (commits:ScCommit[], timeFrequency: TimeFrequency): ScCommitGroups  => {
    commits = commits.sort((a:ScCommit, b:ScCommit) => new Date(b.date).getTime() - new Date(a.date).getTime());

    if(timeFrequency == TimeFrequency.Today){
        const today = moment().format("YYYY-MM-DD");

        const todayGroups:ScCommitGroups = {};
        commits.map((commit:ScCommit) => {
            const dateDay = moment(commit.date).startOf('day').format("YYYY-MM-DD");
            if(dateDay != today)return;
            const date = moment(commit.date).startOf('hour').format("YYYY-MM-DD HH:mm:ss");
            if (!todayGroups[date]) {
                todayGroups[date] = {
                    dateStart:date,
                    dateEnd:date,
                    count: 0,
                    commits: []
                };
            }
            todayGroups[date].commits.push(commit);
            todayGroups[date].count = todayGroups[date].commits.length;
        });

        return todayGroups;
    }
    else if(timeFrequency == TimeFrequency.Daily){
        const dailyGroups:ScCommitGroups = {};
        commits.map((commit:ScCommit) => {
            const date = moment(commit.date).startOf('day').format("YYYY-MM-DD HH:mm:ss");
            if (!dailyGroups[date]) {
                dailyGroups[date] = {
                    dateStart:date,
                    dateEnd:date,
                    count: 0,
                    commits: []
                };
            }
            dailyGroups[date].commits.push(commit);
            dailyGroups[date].count = dailyGroups[date].commits.length;
        });
        return dailyGroups;
    }
    else if(timeFrequency == TimeFrequency.Weekly){

        const weekGroups:ScCommitGroups = {};
        commits.map((commit:ScCommit) => {
            const date = moment(commit.date);
            const dateStart = date.startOf('week').format("YYYY-MM-DD HH:mm:ss");
            const dateEnd = date.endOf('week').format("YYYY-MM-DD HH:mm:ss");
            const dateRange = dateStart+"_"+dateEnd;
            if (!weekGroups[dateRange]) {
                weekGroups[dateRange] = {
                    dateStart:dateStart,
                    dateEnd:dateEnd,
                    count: 0,
                    commits: []
                };
            }
            weekGroups[dateRange].commits.push(commit);
            weekGroups[dateRange].count = weekGroups[dateRange].commits.length;
        });
        return weekGroups;
    }
    else if(timeFrequency == TimeFrequency.Monthly){

        const monthGroups:ScCommitGroups = {};
        commits.map((commit:ScCommit) => {
            const date = moment(commit.date);
            const dateStart = date.startOf('month').format("YYYY-MM-DD HH:mm:ss");
            const dateEnd = date.endOf('month').format("YYYY-MM-DD HH:mm:ss");
            const dateRange = dateStart+"_"+dateEnd;
            if (!monthGroups[dateRange]) {
                monthGroups[dateRange] = {
                    dateStart:dateStart,
                    dateEnd:dateEnd,
                    count: 0,
                    commits: []
                };
            }
            monthGroups[dateRange].commits.push(commit);
            monthGroups[dateRange].count = monthGroups[dateRange].commits.length;
        });
        return monthGroups;
    }
    return {};

}




/** Utils */

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import './styles/app.scss';
import './app.i18n';
import AppRouter from './AppRouter';
import LoginButton from './components/inputs/loginButton/LoginButton';
import PageAuthStatus from './components/pages/auth/PageAuthStatus';
import { getCurrentUser } from './services/UserService';


export default function App() {
  const {  user, isAuthenticated, isLoading } = useAuth0();
  let [scUser, setSCUser] = useState(null);

  useEffect(() => {
    getCurrentUser(user).then(scUser => {
      setSCUser(scUser);
    });

  }, [user]);


  if(isLoading)return (
    <PageAuthStatus
          title={"Authenticating..."}
          message={"Authenticating your user"}
          loading
    />);
  if( !isAuthenticated)return <PageAuthStatus
    title={"Welcome to Scout"}
    message={"Please click login to continue."}
  >
    <LoginButton/>
  </PageAuthStatus>;
  if(scUser === null)return (
    <PageAuthStatus
          title={"Loading Profile..."}
          message={"Retreiving your user information"}
          loading
    />);
  if(scUser === false)return (
    <PageAuthStatus
          title={"Access denied :("}
          message={"Please contact your Scout administrator."}
    />)



  return (
    <div>
      <AppRouter scUser={scUser} authUser={user}/>
    </div>
  );
}

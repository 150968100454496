import { useRef, useState } from 'react';
import ToggleGroup, { IToggleStyle } from '../../inputs/toggleGroup/ToggleGroup';
import './SettingField.scss';
import { ReactComponent as IconChevron } from '~/assets/icons/chevrondown-ico.svg';
import { filter, find, searchFilter } from '~/app/services/API';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react';
import { BbUser } from '~/app/services/Types';
import { getBBProjects, getBBUsers, getBBWorkspaces } from '~/app/services/BitBucketService';
import { getEHUsers, getSTClients, getSTProjects, getSTUsers } from '~/app/services/StreamTimeService';
import { getAuthUsers } from '~/app/services/AuthService';
import { getClients } from '~/app/services/ClientService';

export enum FieldType {
    Text,
    TextArea,
    Number,
    Boolean,
    Date,
    Email,
    Image,
    File,
    Select,
    SearchSelect,
    ToggleSelect,
    MultiSelect,
    Phone,
}

interface IProps {
    title?: string;
    type?: FieldType;
    slug?: string;
    readOnly?: boolean;
    value?: any;
    options?: {key:string, text?:string, icon?: any}[];
    prefix?: string;
    suffix?: string;
    placeholder?: string;
    onChange?: (slug:string, ref:any, oldVal:any, newVal:any) => void;
}

export default function SettingField(props:IProps) {

    let {title, type, slug} = props;
    const fieldRef = useRef(null);
    if(!type)type = FieldType.Text;


    return (
        <div className="SettingField" key={`sf-${slug}`} field-slug={slug} field-type={FieldType[type]}>
            {title && (<div className="label">{title}:</div>)}
            {renderFieldByType(type, fieldRef, props)}
        </div>
    )


}

function renderFieldByType(type: FieldType, ref:any, props: IProps): React.ReactNode {
    const types = {
        "Text": SFTextBox,
        "Number": SFNumberBox,
        "Boolean": SFToggle,
        "Select": SFSelect,
        "ToggleSelect": SFToggleSelect,
        "MultiSelect": SFMultiSelect,
        "SearchSelect": SFSearchSelect,
        "Date": SFDatePicker,
        "Image": SFImagePicker,
    }

    return types[FieldType[type]](props, ref);
}


function SFTextBox(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, placeholder} = props;

    return (
        <div className='SF-Textbox' read-only={(readOnly === true).toString()} >
            <input  type={"text"}
                    ref={ref}
                    readOnly={readOnly}
                    defaultValue={value}
                    placeholder={placeholder}
                    onBlur={(e) => {
                        if(readOnly || !ref || !ref.current || !onChange)return;
                        onChange(slug, ref, value, ref.current.value);
                    }}
            />
        </div>
    )
}

function SFNumberBox(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, prefix, suffix, placeholder} = props;

    return (
        <div className='SF-NumberBox' read-only={(readOnly === true).toString()} >
            {prefix && (
                <div className='SF-Prefix'>{prefix}</div>
            )}
            <input  type={"number"}
                    ref={ref}
                    readOnly={readOnly}
                    defaultValue={value}
                    placeholder={placeholder}
                    onBlur={(e) => {
                        if(readOnly || !ref || !ref.current || !onChange)return;
                        onChange(slug, ref, value, ref.current.value);
                    }}
            />
            {suffix && (
                <div className='SF-Suffix'>{suffix}</div>
            )}
        </div>
    )
}

function SFToggle(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value} = props;
    const [toggled, setToggled] = useState(value);

    return (
        <div className='SF-Toggle'
             read-only={(readOnly === true).toString()}
             is-toggled={toggled.toString()}
             onClick={(e) => {
                // console.log("on tg click", {e, ref, value, type, readOnly})
                if(readOnly || !onChange)return;
                const newVal = !toggled;
                setToggled(newVal);
                onChange(slug, null, value, newVal);
            }}
             >
            <div className='toggleBall'></div>
        </div>
    )
}

function SFSelect(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, options, placeholder} = props;
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(value);

    const selectedOption = selected ? find(options, "key", selected) : null;
    return (
        <div className='SF-Select' read-only={(readOnly === true).toString()} is-open={open.toString()}>
            <div className='selector' onClick={()=> !readOnly && setOpen(!open)}>
                {!selected && placeholder && (
                    <div className='placeholder'>{placeholder}</div>
                )}
                {selectedOption && (
                    <div className='selectedOption'>
                        {selectedOption.icon && selectedOption.icon}
                        <span>
                            {selectedOption.text ? selectedOption.text : selectedOption.key}
                        </span>
                    </div>
                )}

                <div className='chevron'><IconChevron/></div>
            </div>
            {open && (
                <div className='selectorPopout'>
                    {options && options.map(opt => {
                        const active = selected === opt.key;
                        return (
                            <div key={opt.key} className='selectorOption' is-active={active.toString()} onClick={()=> {
                                // console.log("on sl change", { opt, type, readOnly})
                                if(readOnly || !onChange)return;
                                setSelected(active ? "" : opt.key);
                                setOpen(false);
                                onChange(slug, null, value, opt.key);
                            }}>
                                {opt.icon && opt.icon}
                                <span>
                                    {opt.text ? opt.text : opt.key}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

function SFMultiSelect(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, options, placeholder} = props;
    const [open, setOpen] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(value);

    const selectedOptions = selectedKeys ? selectedKeys.map(key => find(options, "key", key)) : null;

    // console.log({selectedKeys, selectedOptions})
    return (
        <div className='SF-MultiSelect' read-only={(readOnly === true).toString()} is-open={open.toString()}>
            <div className='selector' onClick={()=> !readOnly && setOpen(!open)}>
                {!selectedOptions || selectedOptions.length === 0 && placeholder && (
                    <div className='placeholder'>{placeholder}</div>
                )}
                {selectedOptions && selectedOptions.length >= 0 ? (
                    <div className='selectedOptions'>
                        {selectedOptions.map(opt => {
                            return (
                            <div className='selectedOption' key={opt.key}>
                                {opt.icon && opt.icon}
                                <span>
                                    {opt.text ? opt.text : opt.key}
                                </span>
                            </div>
                            );
                        })}
                    </div>

                ): null}
                <div className='chevron'><IconChevron/></div>
            </div>
            {open && (
                <div className='selectorPopout'>
                    {options && options.map((opt) => {
                        const active = selectedKeys.indexOf(opt.key) !== -1;
                        return (
                            <div key={opt.key} className='selectorOption' is-active={active.toString()} onClick={()=> {
                                if(readOnly || !onChange)return;
                                // console.log("on msl change", { opt, type, readOnly}, selectedKeys.indexOf(opt.key))

                                let newSelected = [...selectedKeys];
                                if(active) newSelected.splice(newSelected.indexOf(opt.key), 1);
                                else newSelected.push(opt.key);

                                setSelectedKeys(newSelected);

                                onChange(slug, null, value, newSelected);
                            }}>
                                {opt.icon && opt.icon}
                                <span>
                                    {opt.text ? opt.text : opt.key}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

function SFToggleSelect(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, options} = props;
    const newOptions = {};
    options.map(option => {
        newOptions[option.key] = option.text ? option.text : option.key
    });
    return (
        <div className='SF-ToggleSelect' read-only={(readOnly === true).toString()} >
            <ToggleGroup
                options={newOptions}
                defaultValue={value}
                style={IToggleStyle.Pills}
                color={"var(--neutral-white)"}
                width={-1}
                gap={20}
                onOptionChange={(key: any, text: string) => {
                    // console.log("on tgs change", { key, text, type, readOnly})
                    if(readOnly || !onChange)return;
                    onChange(slug, null, value, key);
                }}
            />
        </div>
    )
}

function SFDatePicker(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, options, placeholder} = props;
    const [date, setDate] = useState(value)
    return (
        <div className='SF-DatePicker' read-only={(readOnly === true).toString()} >
            <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={moment(date).toDate()}
                readOnly={readOnly}
                onChange={(dateNew) => {
                    if(readOnly || !onChange)return;
                    setDate(moment(dateNew).format("YYYY-MM-DD"));
                    onChange(slug, null, date, moment(dateNew).format("YYYY-MM-DD"));
                }}
                placeholderText={placeholder}
                formatStyle="large"
            />
        </div>
    )
}

function SFImagePicker(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, options, placeholder} = props;
    const [image, setImage] = useState(value);
    const [dragActive, setDragActive] = useState(false);
    const fileRef = useRef(null);

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
      };

      const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0]);
        }
      };

      const handleChange = function(e) {
        handleFile(fileRef.current.files[0]);
      }

      const handleFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            // console.log("set new image", reader.result)
            setImage(reader.result)
        });
        reader.readAsDataURL(file);
        // console.log(fileRef.current)
      }

    return (
        <div className='SF-ImagePicker' drag-active={dragActive.toString()} read-only={(readOnly === true).toString()}
             onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}
             >
            <div className='preview' style={{backgroundImage:image ? `url('${image}')`: "unset"}} ></div>
            <div className='contents'>
                <span>{dragActive ? "Drop here!" : "Drop an image to upload, or"}</span>
                <button onClick={()=> fileRef.current.click()}>Choose image</button>
            </div>
            <input ref={fileRef} type={"file"} accept="image/jpeg, image/png, image/jpg" onChange={handleChange}/>
        </div>
    )
}



function SFSearchSelect(props:IProps, ref:any) {
    const {type, slug, readOnly, onChange, value, options, placeholder} = props;
    const [open, setOpen] = useState(false);
    const [keywords, setKeywords] = useState("");
    const [selected, setSelected] = useState(value);
    const [visibleOptions, setVisibleOptions] = useState(options);

    useEffect(()=> {
        let newOptions = [...options];
        if(keywords && keywords.trim().length > 0){
            newOptions = searchFilter(newOptions, "text", keywords.trim());
        }
        newOptions = newOptions.splice(0, 10);
        setVisibleOptions(newOptions);
    }, [keywords])

    const selectedOption = selected ? find(options, "key", selected) : null;

    return (
        <div className='SF-SearchSelect' read-only={(readOnly === true).toString()} is-open={open.toString()}>
            <div className='selector' onClick={(e)=> {
                // console.log("clicked", e);
                const target:any = e.target;
                if(target && target.tagName !== "INPUT"){
                    !readOnly && setOpen(!open);
                }
            }}>
                {!open && !selected && placeholder && (
                    <div className='placeholder'>{placeholder}</div>
                )}
                {!open && selectedOption && (
                    <div className='selectedOption'>
                        {selectedOption.icon && selectedOption.icon}
                        <span>
                            {selectedOption.text ? selectedOption.text : selectedOption.key}
                        </span>
                    </div>
                )}
                {open && (
                    <input className='keywords' type={"text"} placeholder={"Type to search."} onChange={(e)=> {
                        setKeywords(e.target.value || "");
                    }} />
                )}

                <div className='chevron'><IconChevron/></div>
            </div>
            {open && (
                <div className='selectorPopout'>
                    {visibleOptions && visibleOptions.map(opt => {
                        const active = selected === opt.key;
                        return (
                            <div key={opt.key} className='selectorOption' is-active={active.toString()} onClick={()=> {
                                if(readOnly || !onChange)return;
                                setSelected(active ? "" : opt.key);
                                setOpen(false);
                                setKeywords("");
                                onChange(slug, null, value, opt.key);
                            }}>
                                {opt.icon && opt.icon}
                                <span>
                                    {opt.text ? opt.text : opt.key}
                                </span>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}





export function BitBucketUserSelecter(props:{slug:string, value:any, onChange, workspaceSlug}) {
  const {slug, onChange, value, workspaceSlug} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Bitbucket User"}
      placeholder={"Select a user to link"}
      onLoad={async ()=> {
        const users = await getBBUsers(workspaceSlug);
        return users.map(bbUser => ({key: bbUser.account_id, text: `${bbUser.nickname}`}));
      }}
    />
  )
}

export function StreamTimeUserSelecter(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Streamtime User"}
      placeholder={"Select a user to link"}
      onLoad={async ()=> {
        const users = await getSTUsers();
        return users.map(stUser => ({key: stUser.id.toString(), text: `${stUser.firstName} ${stUser.lastName}`}));
      }}
    />
  )
}

export function EmployeeHeroUserSelecter(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Employee Hero User"}
      placeholder={"Select a user to link"}
      onLoad={async ()=> {
        const users = await getEHUsers();
        return users.map(ehUser => ({key: ehUser.id.toString(), text: `${ehUser.firstName} ${ehUser.surname}`}));
      }}
    />
  )
}

export function AuthOUserSelecter(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Auth0 User"}
      placeholder={"Select a user to link"}
      onLoad={async ()=> {
        const users = await getAuthUsers();
        return users.map(user => ({key: user.user_id.toString(), text: `${user.nickname}`}));
      }}
    />
  )
}


export function AsyncSelecter(props:{slug:string, placeholder:string, title:string, value:any, onChange, onLoad:() => Promise<{key:string, text:string}[]>}) {
  const [items, setItems] = useState([]);
  const {slug, placeholder, title, onChange, onLoad, value} = props;

  useEffect(()=> {
    onLoad && onLoad().then(itemss => {
      setItems(itemss);
    });
  }, []);

  return (
    <SettingField
        slug={slug}
        title={title}
        placeholder={placeholder}
        value={!items.length ? "Loading..." : value}
        type={!items.length ? FieldType.Text : FieldType.SearchSelect}
        key={`items_${slug}_${items.length}`}
        options={items.length && items}
        onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {onChange && onChange(slug, newVal)}}
    />
  )
}

export function BitBucketCompanies(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Bitbucket Workspace"}
      placeholder={"Select a workspace to link"}
      onLoad={async ()=> {
        const workspaces = await getBBWorkspaces();
        return workspaces.map(workspace => ({key: workspace.slug, text: `${workspace.name}`}));
      }}
    />
  )
}

export function BitBucketProjects(props:{slug:string, value:any, onChange, workspaceSlug}) {
  const {slug, onChange, value, workspaceSlug} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Bitbucket Project"}
      placeholder={"Select a project to link"}
      onLoad={async ()=> {
        const projects = await getBBProjects(workspaceSlug);
        return projects.map(project => ({key: project.key, text: `${project.name}`}));
      }}
    />
  )
}

export function StreamTimeClients(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Streamtime Client"}
      placeholder={"Select a client to link"}
      onLoad={async ()=> {
        const clients = await getSTClients();

        return clients.map(client => ({key: client.id.toString(), text: `${client.name}`}));
      }}
    />
  )
}

export function StreamTimeProjects(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Streamtime Job"}
      placeholder={"Select a job to link"}
      onLoad={async ()=> {
        const projects = await getSTProjects();

        return projects.map(project => ({key: project.id.toString(), text: `${project.name}`}));
      }}
    />
  )
}

export function ScoutClientsPicker(props:{slug:string, value:any, onChange}) {
  const {slug, onChange, value} = props;

  return (
    <AsyncSelecter
      slug={slug}
      value={value}
      onChange={onChange}
      title={"Client"}
      placeholder={"Select a client to link"}
      onLoad={async ()=> {
        const clients = await getClients();

        return clients.map(client => ({key: client.clientId, text: `${client.name}`}));
      }}
    />
  )
}

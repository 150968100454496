import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { ETeamFull, ScCost, ScTime, ScWidget, Team, TimeType } from "~/app/services/Types";
import Notice from "../../core/notice/Notice";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import CapacityUsage from "./components/CapacityUsage";

interface IProps extends ScWidget{

}


interface IState extends BaseWidgetState{


}

export default class CapacityUsageWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:false,

        }
    }

    public info(){
        return   {
            title: "Capacity Usage",
            className: "CapacityUsage",
            defaultStyle: WidgetStyles.LightRed,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {

    }

    public onRender(context: ScWidgetContext) {
        const {loading} = this.state;
        const project = this.props.context.project;
        //TODO capacity
        if(!project.streamTimeId){
          return (
            <Notice
              title={"StreamTime not connected"}
              message={"To see the project capacity usage of this project please connect with your StreamTime job."}
              actionLink={`/config/project?id=${this.props.context.project.projectId}`}
              actionText={"Setup StreamTime"}
            />
          )
        }

        return (
            <CapacityUsage />
        )
    }

}

/**
 * Scout Toast Service
 */
 import toast, { ToastOptions } from 'react-hot-toast';

const TOAST_LENGTH = 3000;
// const TOAST_LENGTH = 200000;
const TOAST_DEFAULT:ToastOptions = {
  duration: TOAST_LENGTH,
  position: 'top-right',

};

export const notify = (message:string)  => {
    toast(message, {
      ...TOAST_DEFAULT,
      icon: "I",
      style: {

      },
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });
}

export const notifyError = (message:string, log?:any)  => {
  log && console.error(`[Error]`, message, log);

  toast.error(message, {
    ...TOAST_DEFAULT,
    style: {
      backgroundColor: "#ff6969"
    }
  });
}

export const notifySuccess = (message:string)  => {
  toast.success(message, {
    ...TOAST_DEFAULT,
    style: {
      backgroundColor: "#a5ff8f"
    }
  });
}

export const notifyPromise = (promise:any, success?:(data:any)=>void, error?:(err:any)=>void)  => {
  toast.promise(promise, {
    loading: 'Loading',
    success: (data) => {
      success && success(data);
      return 'Success'
    },
    error: (err) => {
      error && error(err);
      return 'Error'
    },
  },
  {
    ...TOAST_DEFAULT,
    icon: "I",
    style: {

    },
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
}

export const notifyLoading = (message:string):string  => {
  return toast.loading(message || 'Loading...', {
    ...TOAST_DEFAULT,
    style: {

    }
  });
}

export const dismissToast = (toastId:string)  => {
  return toast.dismiss(toastId);
}



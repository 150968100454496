import {  useEffect, useState } from 'react';
import { BrowserRouter, useNavigate} from 'react-router-dom';
import { Header } from './components/layout';
import PageHeader from './components/layout/PageHeader';
import PageWrapper from './components/layout/PageWrapper';
import { loadByClient } from './services/BitBucketService';
import { getClients } from './services/ClientService';
import { AppContextProvider } from './services/contexts/AppContext';
import { getProjectsBy } from './services/ProjectService';
import toast, { Toaster } from 'react-hot-toast';


export default function AppRouter(props:any) {


  let [clients, setClients] = useState([]);
  let [client, setClient] = useState(null);
  let [project, setProject] = useState(null);
  let [projects, setProjects] = useState([]);

  const {scUser} = props;

  let [appContext, setAppContext] = useState({
    page: undefined,
    client: client,
    project: project,
    user: scUser,
    clients: clients,
    projects: projects,
    searchParams: undefined,
    navigate: undefined
  });

  useEffect(() => {

    if(!client)return;
    getProjectsBy("clientId", client.clientId).then((projects: any) =>{
      setProjects(projects);
    });

  }, [client]);


  async function loadInfo() {
    let clients = await getClients();
    setClients(clients);
  }

  useEffect(() => {
    loadInfo();
  }, []);

  useEffect(() => {

    setAppContext({
      page: appContext.page ? appContext.page : undefined,
      client: client,
      project: project,
      user: scUser,
      clients: clients,
      projects: projects,
      navigate: appContext.navigate ? appContext.navigate : undefined,
      searchParams: appContext.searchParams ? appContext.searchParams : undefined,
    });

  }, [client, clients, project, projects, scUser]);




  return (
    <BrowserRouter>
      <AppContextProvider context={appContext}>
        <div className='wrapper'>
          <Header />
          <div className='pageWrapper'>
            <PageHeader
              scUser={scUser}
              clients={clients}
              projects={projects}
              onClientChange={(client:any)=>setClient(client)}
              onProjectChange={(project:any)=>setProject(project)}
            />
            <PageWrapper />
          </div>

        </div>
        <Toaster/>
      </AppContextProvider>


    </BrowserRouter>
  );
}

import './Activity.scss';
import { ScActivity as IActivity, ActivityIcon, ActivityType } from '~/app/services/Types';

interface IProps extends IActivity {}

export default function Activity(props: IProps) {
  // const {title, widgetStyle} = props;
  const icon = ActivityIcon(props.type);
  return (
    <div className="Activity">
      <div className='left'>
        {icon}
        <div className='title'>{props.title}</div>
        <div className='message'>{props.message}</div>
      </div>
      <div className='right'>
        {props.more}
        <div className='date'>{props.date}</div>
      </div>
    </div>
  );
}

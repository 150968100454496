import React from "react";
import { ClassType, useEffect } from "react";
import { useState } from "react";
import PageAuthStatus from "../components/pages/auth/PageAuthStatus";
import PageClientEdit from "../components/pages/cms/configs/PageClientEdit";
import PageProjectEdit from "../components/pages/cms/configs/PageProjectEdit";
import PageUserAdd from "../components/pages/cms/configs/PageUserAdd";
import PageUserEdit from "../components/pages/cms/configs/PageUserEdit";
import PageConfiguaration from "../components/pages/cms/PageConfiguration";
import PageFinance from "../components/pages/cms/PageFinance";
import PageMyProfile from "../components/pages/cms/PageMyProfile";
import PageOverview from "../components/pages/cms/PageOverview";
import PagePerformance from "../components/pages/cms/PagePerformance";
import PageSearch from "../components/pages/cms/PageSearch";
import PageUserProfile from "../components/pages/cms/PageUserProfile";
import FeatureManager from "./FeatureManager";
import { IAppContext, IPageContext, ScPages, TimeFrequency, } from "./Types";


export default class PageManager {

    public static pageRegister: ScPages = {
        overview: PageOverview,
        performance: PagePerformance,
        finance: PageFinance,
        search: PageSearch,
        userProfile: PageUserProfile,
        myProfile: PageMyProfile,
        config: PageConfiguaration,
        editUser: PageUserEdit,
        editClient: PageClientEdit,
        editProject: PageProjectEdit,
        addUser: PageUserAdd,
    }

}

export const BuildPage = (props:{appContext:IAppContext, saveAppContext:any, pageSlug:string}) => {
    const {appContext, saveAppContext, pageSlug} = props;

    let [appContextState, setAppContextState] = useState(appContext);
    let [pageSlugState, setPageSlugState] = useState(pageSlug);

    useEffect(()=> {
        setAppContextState(appContext);
        setPageSlugState(pageSlug);
    }, [appContext, pageSlug])

    if(!appContextState){
        //no context
        return (<PageAuthStatus loading={true} title={"Loading Context"} message={"Please wait for the app context to load"}/>)
    }
    const user = appContextState.user;
    const permission = FeatureManager.userHasFeature(user, pageSlug+":page:view");
    if(!permission){
        return (<PageAuthStatus title={"Access denied"} message={"You do not have access to this page."}/>)
    }

    const PageClass: ClassType<any, any, any>  = PageManager.pageRegister[pageSlug];

    if(typeof PageClass === "undefined"){
        //page not implemented
        return (<PageAuthStatus title={"404"} message={"Page content doesnt exist."}/>)
    }

    if(!appContextState.page || appContextState.page != PageClass){
        appContextState.page = PageClass;
        saveAppContext(appContextState);
    }

    let pageContext:IPageContext = {
        appContext: appContextState,
        page: PageClass,
        client: appContextState.client,
        project: appContextState.project,
        timeFrequency:TimeFrequency.Monthly
    }

    const uniqueUpdateKey = pageSlug+ "_"+appContext && (appContext.client && appContext.client.clientId)+"_"+(appContext.project && appContext.project.projectId)+"_"+(appContext.timeUpdate)
    console.log("App context updated ", uniqueUpdateKey);
    return React.createElement(PageClass, {
        key: pageSlug+uniqueUpdateKey,
        slug: pageSlug,
        context: pageContext,
    });
}

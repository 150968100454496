/**
 * Scout Project Service
 */

import { cache, cached, call, EP, fill, filter, find, post, sort } from "./API";
import { ScJob, ScProject, ScUser } from "./Types";

/** API */

export const getProjects = async (): Promise<ScProject[]>  => {
    const data = await call("GET", EP.getProjects, "projects");
    if(!data)return [];
    let projects = data.projects;
    projects = sort(projects, "name");

    return projects;
}

export const getJobs = async (): Promise<ScJob[]>  => {
    const data = await call("GET", EP.getJobs, "jobs");
    if(!data)return [];
    let jobs = data.jobs;
    jobs = sort(jobs, "name");

    return jobs;
}

export const getJobsBy = async (by: "project" | "user", identity:string): Promise<ScJob[]>  => {
    const data = await call("GET", fill(EP.getJobsBy[by], [[by, identity]]), `jobs_by_${by}_${identity}`);
    if(!data)return [];
    let jobs = data.jobs;
    jobs = sort(jobs, "name");

    return jobs;
}

export const updateProject = async (projectId:string, propsToUpdate:any): Promise<any>  => {
  const data = await post(fill(EP.updateProjectBy["projectId"], [["projectId", projectId]]), propsToUpdate);
  if(!data)return [];
  return data;
}



/** Static */


/** TODO Api this */
export const getProjectsBy = async (by: "clientId", identity:string): Promise<ScProject[]>  => {
    const cacheKey = `projects_by_${by}_${identity}`;
    if(cacheKey && cached(cacheKey)) return cached(cacheKey);

    let projects:ScProject[]  = await getProjects();
    let filteredProjects:ScProject[] = filter(projects, by, identity);

    cache(cacheKey, filteredProjects);
    return filteredProjects;
}


export const getProject = async (identity:string): Promise<ScProject>  => {
    return find((await getProjects()), "projectId", identity);
}



/** Utils */

import React from "react";
import Widget, {  WidgetStyles } from "../core/widget/Widget";
import {IWidgetContext, ScWidget } from "../../services/Types";

export interface BaseWidgetState{
    loading: boolean;
}

export default abstract class BaseWidget<T extends ScWidget, J extends BaseWidgetState> extends React.Component<T, J> {

    protected dontUpdate = false;

    constructor(props:T){
        super(props);

        this.onInit();
    }

    public info(){
        return {
            title: "Base Widget",
            className: "",
            defaultStyle: WidgetStyles.Normal,
            topLayout: "inline"
        }
    }

    public onInit(){}

    public async onPreLoad(context: ScWidgetContext){}

    public async onPostLoad(context: ScWidgetContext){}

    public async onRefresh(context: ScWidgetContext){}

    public onRender(context: ScWidgetContext){}

    public async onUpdated(context: ScWidgetContext){}

    public async onDispose(context: ScWidgetContext){}

    public onRenderTop(context: ScWidgetContext){}

    public componentDidMount(): void {
        const widgetContext = this.props.context;
        this.onPostLoad(widgetContext);
    }

    public componentDidUpdate(prevProps: Readonly<T>, prevState: Readonly<J>, snapshot?: any): void {
        const widgetContext = this.props.context;
        if(this.dontUpdate){
            this.dontUpdate = false;
            return;
        }
        this.onUpdated(widgetContext);
    }

    public componentWillUnmount(): void {
        const widgetContext = this.props.context;
        this.onDispose(widgetContext);
    }

    public render(): React.ReactElement<T>
    {
        const widgetContext = this.props.context;
        const {slug} = this.props;
        const {title, className, defaultStyle, topLayout} = this.info();
        const {size, page, widgetStyle, match} = widgetContext;
        const {loading} = this.state;

        const component = this.onRender(widgetContext);

        return (
            <Widget key={slug}
                    data-size={size}
                    widgetStyle={widgetStyle === undefined ? defaultStyle : widgetStyle}
                    title={title}
                    className={className}
                    topLayout={topLayout}
                    top={this.onRenderTop(widgetContext)}
                    fit={match}
                    loading={loading}
            >
                    {component}
            </Widget>
        );
    }



    public getProps():T {
        return this.props;
    }

    public refresh(context: ScWidgetContext){

        this.forceUpdate(()=> {
            this.onRefresh(context);
        });
    }

    public setState(newState:any, callback?:any, forceUpdate=false){
        if(!forceUpdate)this.dontUpdate = true;
        super.setState(newState, callback);
    }

}

export interface ScWidgetContext extends IWidgetContext{

}

/**
 * Scout Auth Service
 */
 import {User} from 'auth0';
import { cache, cached, call, EP } from './API';

/** API */

//TODO route through scout api
export const getAuthUsers = async (): Promise<User[]>  => {
  const data = await call("GET", EP.getAuthUsers, "authUsers");
  if(!data)return [];
  let users = data.users;
  // users = sort(users, "firstName");

  return users;
}




/** Static */






/** Utils */


import { useEffect, useState } from "react";
import Grid from "~/app/components/core/grid/Grid";
import UserCard from "~/app/components/cards/user/UserCard";
import { ScTime, ScUser, ScUserTime, Team } from "~/app/services/Types";
import Util from "~/app/services/Util";
import Notice from "~/app/components/core/notice/Notice";




interface IUsersBlockProps{
    showAll: boolean;
    times: ScTime[];
    userTimes: ScUserTime[];
    users: ScUser[];
    team?: Team;
    maximum?: number;
}





export default function UsersList(props:IUsersBlockProps) {



    const {maximum, team, showAll, times, userTimes, users} = props;
    const max = maximum ? maximum : 4;
    const teamType = team ? team : Team.Red;
    const [showingAll, setShowAll] = useState(showAll);
    const [usersToShow, setUsersToShow] = useState([]);
    let showing = 0;
    let [reachedCap, setReachedCap] = useState(false);

    useEffect(() => {
      const showUsers = users.filter((user:ScUser, index:number) =>{
        if(showing >= max){
          setReachedCap(true);
          if(!showingAll)
              return false;
        }
        const userTeam = user.team == null || user.team === "red" ? Team.Red : Team.Blue;
        const timeLogged = Util.getSTTimeLoggedByUser(userTimes, user.streamtime_id);
        if(userTeam !== teamType)return false;
        if(!timeLogged)return false;
        showing++;
        return true;
      });
      setUsersToShow(showUsers);
    }, [users, userTimes]);



    if(!usersToShow || usersToShow.length === 0){
      return (
        <Notice
          title={"No "+ Team[team] + " Team Users"}
          message={"There are no "+ Team[team] + " Team users yet"}
        />
      )
    }

    return (
        <div team-type={Team[team]}>

            <Grid columns={1} gap={0}>
            {

                usersToShow.map((user:ScUser, index:number) =>{

                    const timeLogged = Util.getSTTimeLoggedByUser(userTimes, user.streamtime_id);
                    return (
                        <UserCard
                            key={user.email}
                            user={user}
                            data-size={12}
                            timeLogged={timeLogged}
                            dailyAverage={Util.getSTTimeLoggedDailyAverageByUser(userTimes, user.streamtime_id)}
                            lastEntry={Util.getSTLastLoggedTimeByUser(userTimes, user.streamtime_id)}/>
                    )
                })
            }
            </Grid>

            {reachedCap ? <a className={"showMore"} role={"button"} onClick={()=>setShowAll(!showingAll)}>{showingAll ? "Show less" : "Show more"}</a>: null}

        </div>
    );
}


interface IProps {
}


export default function PageNotFound(props:IProps) {

  return (
    <div className="page">
      <main>
        <h1>404 Page not found</h1>
      </main>
    </div>
  );
}

import { Suspense, useEffect} from 'react';
import {  Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import PageNotFound from '../pages/cms/NotFound';

import useAppContext from '../../services/hooks/useAppContext';
import { BuildPage } from '../../services/PageManager';


interface IProps {
}


export default function PageWrapper(props:IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const LoadingMessage = () => <div>Loading..,</div>;

  const { appContext, saveAppContext } = useAppContext();
  const {user, clients, projects, client, project} = appContext;

  const buildData = {
    appContext, saveAppContext
  }
  useEffect(() => {

    appContext.navigate = navigate;
    appContext.searchParams = searchParams;
    saveAppContext(appContext);

  }, []);

  useEffect(() => {

    appContext.searchParams = searchParams;
    saveAppContext(appContext);
  }, [searchParams]);

  return (
      <Suspense fallback={<LoadingMessage />}>
        <Routes>
          <Route path="/overview" element={<BuildPage pageSlug={'overview'} {...buildData} />} />
          <Route path="/" element={<BuildPage pageSlug={'overview'} {...buildData} />} />
          <Route path="/projects" element={<BuildPage pageSlug={'performance'} {...buildData} />} />
          <Route path="/finance" element={<BuildPage pageSlug={'finance'} {...buildData} />} />
          <Route path="/user" element={<BuildPage pageSlug={'userProfile'} {...buildData} />} />
          <Route path="/profile" element={<BuildPage pageSlug={'myProfile'} {...buildData} />} />
          <Route path="/search" element={<BuildPage pageSlug={'search'} {...buildData} />} />
          <Route path="/config" element={<BuildPage pageSlug={'config'} {...buildData} />} />
          <Route path="/config/user" element={<BuildPage pageSlug={'editUser'} {...buildData} />} />
          <Route path="/config/client" element={<BuildPage pageSlug={'editClient'} {...buildData} />} />
          <Route path="/config/project" element={<BuildPage pageSlug={'editProject'} {...buildData} />} />
          <Route path="*" element={<PageNotFound/>} />

        </Routes>
      </Suspense>
  );
}

import Grid from "~/app/components/core/grid/Grid";
import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { IWidgetContext, IWidgetData, ScWidget} from "~/app/services/Types";
import WidgetManager from "~/app/services/WidgetManager";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";

interface IProps extends ScWidget{

}


interface IState extends BaseWidgetState{

    
}

export default class GridWidget extends BaseWidget<IProps, IState> {
    
    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            
        }
    }

    public info(){
        return   {
            title: null, 
            className: "GridWidget", 
            defaultStyle: WidgetStyles.Normal,
            topLayout: "none"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {
        this.setState({loading:false});
    }

    private renderSubWidgets(widgets){
        const widgetContext = this.props.context;
        const {appContext, pageContext, timeFrequency} = widgetContext;
        if(!widgets)return;
        return widgets.map((widget:IWidgetData) => {

            const widgetContext:IWidgetContext = {
                appContext: appContext,
                pageContext: pageContext,

                page: pageContext.page,
                client: pageContext.client,
                project: pageContext.project,
                timeFrequency: timeFrequency,
                size: widget.size,
                match: widget.match,
                widgetStyle: widget.widgetStyle,
                data: widget.data,
            }

            return WidgetManager.buildWebpart(widget.slug, widgetContext);
        });
    }

    public onRender(context: ScWidgetContext) {
        const widgetContext = this.props.context;
        const {data} = widgetContext;
        const {widgets, columns} = data;
        return (
            <Grid columns={columns}>
                  {this.renderSubWidgets(widgets)}
            </Grid>
        )
    }

}
/**
 * Scout User Service
 */

import { User } from "@auth0/auth0-react";
import { cache, cached, call, EP, fill, find, post, sort } from "./API";
import { getProject } from "./ProjectService";
import { ScUser } from "./Types";

/** API */

export const getUsers = async (): Promise<ScUser[]>  => {
    const data = await call("GET", EP.getUsers, "users");
    if(!data)return [];
    let users = data.users;
    users = sort(users, "firstName");

    return users;
}

export const updateUser = async (email:string, propsToUpdate:any): Promise<ScUser[]>  => {
  const data = await post(fill(EP.updateUserBy["email"], [["email", email]]), propsToUpdate);
  if(!data)return [];
  return data;
}

export const getUserBy = async (by: "email" | "bitbucket_id", identity:string): Promise<ScUser>  => {
    //TODO filter users by their Bitbucket accountid
    if(by === "bitbucket_id"){
        return await findUserBy("bitbucket_id", identity);
    }
    const data = await call("GET", fill(EP.getUserBy[by], [[by, identity]]), `user_by_${by}_${identity}`);
    if(!data)return undefined;
    let user = data.user;

    return user;
}


/** Static */

export const getRandomUser = async (): Promise<ScUser>  => {
    const all = await getUsers();
    return all[Math.floor(Math.random()*all.length)];
}

export const getCurrentUser = async (authUser: User): Promise<ScUser>  => {
    const cacheKey = "currentUser";
    if(cacheKey && cached(cacheKey)) return cached(cacheKey);
    if(!authUser)return undefined;
    const foundUser:ScUser = await findUserByAuth(authUser);
    if(!foundUser)return undefined;
    foundUser.authUser = authUser;

    if(cacheKey) return cache(cacheKey, foundUser);
    return foundUser;
}

export const getUsersBy = async (by: "projectId", identity:string): Promise<ScUser[]>  => {
    const cacheKey = `users_by_${by}_${identity}`;
    if(cacheKey && cached(cacheKey)) return cached(cacheKey);

    let project = await getProject(identity);
    if(!project) return undefined;
    if(!project.users) return [];

    let users: ScUser[] = [];
    project.users.map(async (email:string) => {
        const user = await findUserBy("email", email);
        if(user)users.push(user);
    });


    if(cacheKey) return cache(cacheKey, users);
    return users;
}





/** Utils */
export const findUserByAuth = async (authUser: User): Promise<ScUser> => {
    let foundUser:ScUser = await findUserBy("authId", authUser.sub);
    if(!foundUser)foundUser = await findUserBy("email", authUser.email);
    return foundUser;
}

export const findUserBy = async (property:string, value:string): Promise<ScUser> => {
    return find((await getUsers()), property, value);
}

import logo from "../../../../assets/images/ScoutLogo.png";
import Loading from "../../core/loading/Loading";


interface IProps {
  title: string;
  message?: string;
  loading?: boolean;
  children?: any;
  inner?:boolean;
}

export default function PageAuthStatus(props:IProps) {

  return (
    <div className={`fullPage authStatus ${props.inner && "inner"}`}>
      <main>
        <div className="statusBlock">
          <div className="logo" style={{backgroundImage: `url('${logo}')`}} />
          <h3>{props.title}</h3>
          <p>{props.message}</p>
          <div className="statusblockChildren">
            {props.loading ? (
              <Loading/>
            ) : null}
            {props.children}
          </div>
        </div>
      </main>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { getNavItems, renderNavItems } from '~/app/services/NavigationService';
import { ScNavItem } from '~/app/services/Types';

import { ReactComponent as IconChevron } from "~/assets/icons/chevronleft-ico.svg";

export default function Header() {
  let [open, setOpen] = useState(true);
  let [navItems, setNavItems] = useState([]);
  let [hoveringItem, setHoveringItem] = useState(-1);
  let [activeItem, setActiveItem] = useState(-1);
  let [hoverDotY, setHoverDotY] = useState(-1);

  useEffect(() => {
    getNavItems().then((navItemList) =>{
      setNavItems(navItemList);
    })
    

    if(activeItem == -1)return;
    let y = activeItem * (24) + 24 + activeItem * 20 -4;
    setHoverDotY(y);
  }, []);

  useEffect(() => {
    if(hoveringItem == -1){
      let y = activeItem * (24) + 24 + activeItem * 20 -4;
      setHoverDotY(y);
      return;
    };
    let y = hoveringItem * (24) + 24 + hoveringItem * 20 -4;
    setHoverDotY(y);
  }, [hoveringItem]);

  useEffect(() => {
    if(activeItem == -1)return;
    let y = activeItem * (24) + 24 + activeItem * 20 -4;
    setHoverDotY(y);
  }, [activeItem]);


  const {navIconsEls, navItemEls} = renderNavItems(navItems, hoveringItem, activeItem, 
    (navItem:ScNavItem, index:number)=>{
      setHoveringItem(index);
    }, 
    ()=>{
      setHoveringItem(-1);
    }, 
    (navItem:ScNavItem, index:number)=>{
      setActiveItem(index)
    }, 
  );

  return (
    <header className={"sidebar "+ (open ? "" : "closedHeader")}>
      <div className="logo"></div>
      <div className='sidebarLinks'>
        <div className='sidebarLinkIcons'>
          <div className='hoverItem ' style={(hoveringItem == -1 && activeItem == -1 ? 
          {
              transform:`translateY(${hoverDotY}px) scale(0) `
          }: 
          {
            transform:`translateY(${hoverDotY}px) scale(1) `
          })}></div>
          {navIconsEls}
        </div>
        <div className='sidebarLinkItems'>{navItemEls}</div>
      </div>
      
      <div> </div>

      <div className='headerCloser' onClick={()=> setOpen(!open)}><IconChevron/></div>
    </header>
  );
}

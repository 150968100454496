/**
 * Scout Activity Service
 */

import moment from "moment";
import { convertCommits, filterCommitsByUser, getBBCommits, getBBCommitsByProject, getBBProjects, getBBRepos } from "./BitBucketService";
import { ActivitySource, ActivityType, ScActivity, ScCommit, ScJob, ScProject, ScUser } from "./Types";

/** API */



/** Static */


export const getActivities = async (project:ScProject): Promise<ScActivity[]>  => {
    if(!project.bitbucket_project_id)return null;

    const commits = await getBBCommitsByProject(project);
    if(!commits)return null;
    const scCommits:ScCommit[] = await convertCommits(commits);
    const commitActivities:ScActivity[] = commitToActivity(scCommits)

    return [...commitActivities];
}

export const getActivitiesByUser = async (project:ScProject, user:ScUser): Promise<ScActivity[]>  => {
  if(!project.bitbucket_project_id)return null;

    const commits = await getBBCommitsByProject(project);
    if(!commits)return null;

    const scCommits:ScCommit[] = await convertCommits(commits);
    const onlyUserCommits:ScCommit[] = filterCommitsByUser(scCommits, user);
    const commitActivities:ScActivity[] = commitToActivity(onlyUserCommits)

    return [...commitActivities];
}

export const commitToActivity = (commits:ScCommit[]): ScActivity[]  => {
    return commits.map((commit:ScCommit) => {
        return {
            source: ActivitySource.Git,
            type: ActivityType.Commit,
            title: commit.user ? commit.user.firstName : "Unknown User",
            message: `commit to '${commit.getRepo().name}'`,
            date: moment(commit.date).format("ddd MMM D YYYY"),
            more: (<i className="message">{commit.message}</i>),
        }
    });
}


export const filterLatestActivities = (activities:ScActivity[], max=6): ScActivity[]  => {

    activities = sortActivities(activities);

    return [...activities].slice(0, max);
}

export const sortActivities = (activities:ScActivity[]): ScActivity[]  => {
    return activities.sort((a:ScActivity, b:ScActivity) => new Date(b.date).getTime() - new Date(a.date).getTime());
}



/** Utils */

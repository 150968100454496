import Activity from '~/app/components/core/activity/Activity';
import Notice from '~/app/components/core/notice/Notice';
import { filterLatestActivities } from '~/app/services/ActivityService';
import { ScActivity as IActivity, ActivitySource, ActivityType, EActivitySource, ScActivity } from '~/app/services/Types';
import './RecentActivity.scss';

interface IProps {
  filter: ActivitySource;
  activities:IActivity[];
}

export default function RecentActivity(props: IProps) {
  const {filter, activities} = props;
  if(!activities)return null;

  let toShow = activities.filter(activity => {
    if(filter == ActivitySource.Any)return true;
    return filter == activity.source;
  });
  const trimmedActivities:ScActivity[] = filterLatestActivities(toShow, 6);


  if(trimmedActivities.length === 0){
    const filterMessage = ["All", "Git commits", "File changes"][filter];
    return (
      <Notice
        title={"No "+filterMessage}
        message={`No ${filterMessage} for this project yet.`}
      />
    )
  }

  return (
    <>
      {trimmedActivities && trimmedActivities.map((activity:IActivity, index:number) => {

        return (
          <Activity key={index} {...activity}/>
        )
      })}
    </>
  );
}

import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../loading/Loading';
import './Widget.scss';

export enum WidgetStyles {
  Normal,

  Orange,
  Blue,
  Red,

  LightRed,
  LightBlue,

  Settings
}

export interface IWidgetProps {
  title?: string;
  widgetStyle?: WidgetStyles;
  children?: any;
  className?: string;
  top?:any;
  fit?:boolean;
  loading?:boolean;
  topLayout?:string;
}

export default function Widget(props: IWidgetProps) {
  const { title, widgetStyle, className, top, fit, loading, topLayout } = props;
  const WidgetStyle = widgetStyle ? widgetStyle : WidgetStyles.Normal;
  const [isLoading, setLoading] = useState(loading);

  useEffect(()=> {
    setLoading(loading)
  }, [loading])

  return (
    <div className={['Widget', className].join(' ')} 
      widget-style={WidgetStyles[WidgetStyle]} 
      style={{minHeight: (fit ? "fit-content": "300px")}}>
      <div className='widgetTop' top-layout={topLayout}>
        {title ? <h5 className="WidgetTitle">{title}</h5> : null}
        {top ? (<div>{top}</div>) : null}  
      </div>
      {isLoading ? (
        <Loading center />
      ): (
        props.children
      )}

    </div>
  );
}

import { useEffect, useState } from 'react';
import { ReactComponent as IconChevron } from '~/assets/icons/chevronleft-ico.svg';
import './Chooser.scss';

interface IChooserOption {
  key: string;
  title: string;
}

interface IChooserProps {
  title: string;
  placeholder: string;
  options: IChooserOption[];
  defaultIndex?: number;
  onOptionChange: (index: number, key: string, option: IChooserOption) => void;
}

export const TYPE_OPTION = 0;
export const TYPE_SEPERATER = 1;
export const TYPE_ACTION = 2;

export default function Chooser(props: IChooserProps) {
  const { options, title, placeholder, defaultIndex, onOptionChange } = props;
  const optionKeys = Object.keys(options);
  let [openedOnce, setOpenedOnce] = useState(false);
  let [open, setOpen] = useState(false);
  const defaultOption = options[props.defaultIndex] ? options[props.defaultIndex] : 0;
  let [selectedIndex, setSelectedIndex] = useState(props.defaultIndex ? props.defaultIndex : 0);
  const selectedItem = options[selectedIndex];

  useEffect(() => {
    const option = options[selectedIndex];
    if (!option) return;

    onOptionChange && onOptionChange(selectedIndex, option.key, option);
    setOpen(false);
  }, [selectedIndex]);

  useEffect(() => {
    if (!openedOnce) setSelectedIndex(props.defaultIndex);
  }, [props.defaultIndex]);

  useEffect(() => {
    if (open && !openedOnce) setOpenedOnce(true);
  }, [open]);

  let Options = options.map((option: any, index: any) => {
    const isActive = selectedIndex === index;

    return (
      <div className={'chooserOption ' + (isActive ? '  is-active ' : '')} key={index} onClick={() => setSelectedIndex}>
        {option.title}
      </div>
    );
  });

  return (
    <div className={'chooser'} is-open={open.toString()} open-initial={openedOnce.toString()}>
      <div className={'selector'}>
        <label>{title}</label>
        <div className={'selectedOption'} onClick={() => setOpen(!open)}>
          <span>{selectedItem ? selectedItem.title : placeholder}</span>
          <IconChevron />
        </div>
      </div>
      <div className={'chooserPopup'}>{Options}</div>
    </div>
  );
}


export default class FeatureManager {

    public static features() {
       return [
           "overview:page:view",
           "overview:page:other",
           "performance:page:view",
           "overview:page:view",
           "finance:page:view",
           "search:page:view",
           "userProfile:page:view",
           "myProfile:page:view",
           "config:page:view",
           "editUser:page:view",
           "addUser:page:view",
       ]
    }

    public static getUserFeatures(user: any) {
       //Static for now
       const group = this.getUserFeatureGroup(user);
       let features: string[] = this.getGroupFeatures(group);

       features = [...features,
        "overview:*",
        "overview:page:*",
        "overview:page:view:*",
        "overview:page:view",
        "performance:*",
        "finance:*",
        "finance:*",
        "myProfile:*",
        "userProfile:*",
        "search:*",
        "search:page:*",
        "config:*",
        "config:page:*",
        "config:page:view:*",
        "editUser:*",
        "editUser:page:*",
        "editUser:page:view:*",
        "addUser:*",
        "addUser:page:*",
        "addUser:page:view:*",
        ]
       return features;
    }

    public static getGroupFeatures(group: any) {
        //Static for now
        return [
            "finance:*",
            "finance:page:*",
            "finance:page:view",
        ]
    }

    public static userHasFeature(user: any, feature:string) {
      //TODO finish
      return true;


       const userFeatures = this.getUserFeatures(user);

       return this.isFeatureInList(userFeatures, feature);
    }


    public static groupHasFeature(group: any, feature:string) {
        const groupFeatures = this.getGroupFeatures(group);
        return this.isFeatureInList(groupFeatures, feature);
    }

    public static getUserFeatureGroup(user: any) {
        //Static for now
        return "Hideandseek";
    }



    public static isFeatureInList(features: string[], feature: string) {
        let featureObj = feature.split(":");

        //Three levels for now
       if(featureObj.length > 0 && features.indexOf(`${featureObj[0]}:*`) != -1)return true;

       if(featureObj.length > 1 && features.indexOf(`${featureObj[0]}:${featureObj[1]}:*`) != -1)return true;

       if(featureObj.length > 2 && features.indexOf(`${featureObj[0]}:${featureObj[1]}:${featureObj[2]}`) != -1)return true;


       return false;
    }



}

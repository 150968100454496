import { ScProject } from "~/app/services/Types";

interface IProps {
    project: ScProject;
}


export default function ProjectCard(props:IProps) {
    const project = props.project;
    
 
    return <div className="jobCard">
        <h5>Project</h5>
        <p>Name: {project.name}</p>
    </div>


}
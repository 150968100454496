import { useEffect, useState } from 'react';
import { ReactComponent as IconChevron } from '~/assets/icons/chevronleft-ico.svg';
import './Dropdown.scss';

interface IDropdownProps {
  options: any;
  defaultIndex?: number;
  onOptionChange: any;
}

export const TYPE_OPTION = 0;
export const TYPE_SEPERATER = 1;
export const TYPE_ACTION = 2;

export default function Dropdown(props: IDropdownProps) {
  const options = props.options;
  const optionKeys = Object.keys(options);
  let [openedOnce, setOpenedOnce] = useState(false);
  let [open, setOpen] = useState(false);
  const defaultOption = options[props.defaultIndex] ? options[props.defaultIndex] : 0;
  let [selectedIndex, setSelectedIndex] = useState(props.defaultIndex ? props.defaultIndex : 0);
  const selectedItem = options[selectedIndex];

  useEffect(() => {
    const option = options[selectedIndex];
    if (!option) return;
    props.onOptionChange(selectedIndex, option.key, option);
    setOpen(false);
  }, [selectedIndex]);

  useEffect(() => {
    if (!openedOnce) setSelectedIndex(props.defaultIndex);
  }, [props.defaultIndex]);

  useEffect(() => {
    if (open && !openedOnce) setOpenedOnce(true);
  }, [open]);

  let Options = options.map((option: any, index: any) => {
    const type = option.type;
    if (type === TYPE_SEPERATER) return <hr key={"seperator_"+index}/>;

    const isActive = selectedIndex === index;

    const icon = option.icon;
    const action = type === TYPE_ACTION ? () => {option.action();setOpen(false)} : () => setSelectedIndex(index);
    return (
      <div className={'dropdownOption ' + (isActive ? '  is-active ' : '')} key={"dropdown_"+index} onClick={() => action()}>
        {icon}
        {option.title}
      </div>
    );
  });

  return (
    <div className={'dropdown ' + (open && ' open ') + (openedOnce && ' openedOnce ')}>
      <button onClick={() => setOpen(!open)}>
        <span>{selectedItem ? selectedItem.title : 'Dropdown'}</span> <IconChevron />{' '}
      </button>
      <div className={'dropdownPopup '}>{Options}</div>
    </div>
  );
}


import "./Grid.scss";


interface IGridProps{
    gap?:number;
    columns?:number;
    children?: any;
}

export default function Grid(props:IGridProps) {

    let {columns, gap, children} = props;
    const itemSize = 12/columns;
    children = !Array.isArray(children) ? [children] : children;
    let gridItems = children ? children.map((child: any, index:number) => {
        if(!child)return;
        let columnSize = child.props["data-size"] ? child.props["data-size"] : itemSize;
        return (<div key={index} 
                     className="GridItem"
                     grid-size={columnSize}
                     
                     >{child}</div>)
    }): [];

    return (
        <div className="Grid" style={{
            gap:(gap != null ? "20px " + gap : "20px"),
            "--grid-gap": (gap != null  ? gap+"px" : "20px"),
            } as React.CSSProperties}>
            {gridItems}
        </div>
    );
}
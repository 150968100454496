import moment from "moment";
import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { filter } from "~/app/services/API";
import { convertCommits, filterCommitsByTeam, filterCommitsByUser, getBBCommits, getBBProjects, getBBRepos, groupCommitsBy } from "~/app/services/BitBucketService";
import { ScCommit, ScCommitGroup, ScCommitGroups,ScWidget, Team, TimeFrequency, TimeType } from "~/app/services/Types";
import Util from "~/app/services/Util";
import Notice from "../../core/notice/Notice";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import CommitsChart from "./components/CommitsChart";

interface IProps extends ScWidget{
}


interface IState extends BaseWidgetState{
    allCommits:ScCommit[],
    redCommits:ScCommitGroup[],
    blueCommits:ScCommitGroup[]
    userCommits:ScCommitGroup[]
    chartData:any

}

export default class GitWidget extends BaseWidget<IProps, IState> {

    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            allCommits:[],
            redCommits:[],
            blueCommits:[],
            userCommits:[],
            chartData:false
        }
    }

    public info(){
        return   {
            title: "Git Commits",
            className: "",
            defaultStyle: WidgetStyles.Normal,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {
        let {timeFrequency, data} = context;

        const projects = await getBBProjects("hide-and-seek");
        const repos = await getBBRepos("hide-and-seek", "SCOUT");
        const commits = await getBBCommits("hide-and-seek", "scout-front-end-onur");

        const scCommits:ScCommit[] = await convertCommits(commits);
        const onlyRedTeam:ScCommit[] = filterCommitsByTeam([...scCommits], Team.Red);
        const onlyBlueTeam:ScCommit[] = filterCommitsByTeam([...scCommits], Team.Blue);
        const onlyUser:ScCommit[] = filterCommitsByUser([...scCommits], data.user);

        let redGrouped:ScCommitGroups = groupCommitsBy(onlyRedTeam, timeFrequency);
        let blueGrouped:ScCommitGroups = groupCommitsBy(onlyBlueTeam, timeFrequency);
        let userGrouped:ScCommitGroups = data.user ? groupCommitsBy(onlyUser, timeFrequency) : {}

        this.setState(
            {
                allCommits:scCommits,
                redCommits:Object.values(redGrouped),
                blueCommits:Object.values(blueGrouped),
                userCommits: Object.values(userGrouped)
            },
            () => {
                const chartData = this.generateDataForLineChart();
                this.setState({
                    chartData,
                    loading:false,
                })
            }
        );

    }

    public onRender(context: ScWidgetContext) {
        const widgetContext = this.props.context;
        const {data, timeFrequency, project} = widgetContext;
        const {timeType} = data;
        const {loading, chartData, allCommits} = this.state;
        if(loading)return null;

        if(!project.bitbucket_project_id){
          return (
            <Notice
              title={"Requires a BitBucket connection"}
              message={"To see the latest git commits of this project please connect with your BitBucket account."}
              actionLink={`/config/project?id=${project.projectId}`}
              actionText={"Setup BitBucket"}
            />
          )
        }
        if(!chartData){
          return (
            <Notice
              title={"No Git Commits"}
              message={"No Git commits for this project yet."}
            />
          )
        }

        return (
            <CommitsChart data={chartData}  type={timeType} frequency={timeFrequency}/>
        )
    }

    public getLabel(commitGroup:ScCommitGroup, frequency:TimeFrequency){
        if(frequency == TimeFrequency.Monthly){
            return Util.getMonthFromDate(commitGroup.dateStart);
        }else if(frequency == TimeFrequency.Weekly){
            return Util.getWeeksAgo(commitGroup.dateStart);
        }else if(frequency == TimeFrequency.Today){
            return moment(commitGroup.dateStart).format("h:00 A");
        }
        return "NA";
    }


    public getChartLabels(timeFrequency: TimeFrequency){
        const max = 5;
        return [
            ()=>Util.getMonthsBehind(max),//Monthly
            ()=>Util.getWeeksBehind(max),//Weekly
            ()=>Util.getHoursBehind(max),//Today
            ()=>Util.getDaysBehind(max),//Daily
        ][timeFrequency]();
    }

    public getFilteredDatas(timeFrequency: TimeFrequency, labels:string[], redCommits:ScCommitGroup[], blueCommits:ScCommitGroup[], userCommits:ScCommitGroup[]){
        let redFiltered = [...redCommits]
        let blueFiltered = [...blueCommits]
        let userFiltered = [...userCommits]

        const filter = [
            (data:ScCommitGroup[])=> {//Monthly
                return data.filter(commitGroup => labels.indexOf(moment(commitGroup.dateStart).format("MMMM")) != -1)
            },
            (data:ScCommitGroup[])=> {//Weekly
                return data.filter(commitGroup => {
                    return labels.some(label => {
                        const weekEnd = moment(label).endOf("week").format("YYYY-MM-DD 23:59:59");
                        return moment(commitGroup.dateStart).isBetween(label, weekEnd, null, '[]');
                    });
                })
            },
            (data:ScCommitGroup[])=> {//Today
                return data
            },
            (data:ScCommitGroup[])=> {//Daily
                return data.filter(commitGroup => {
                    return labels.some(label => {
                        const dayEnd = moment(label).endOf("day").format("YYYY-MM-DD 23:59:59");
                        return moment(commitGroup.dateStart).isBetween(label+" 00:00:00", dayEnd, null, '[]');
                    });
                })
            },
        ][timeFrequency];

        redFiltered = filter(redFiltered);
        blueFiltered = filter(blueFiltered);
        userFiltered = filter(userFiltered);

        return {redFiltered, blueFiltered, userFiltered}
    }

    public fillBlankData(timeFrequency: TimeFrequency, labels:string[], redData:ScCommitGroup[], blueData:ScCommitGroup[], userData:ScCommitGroup[]){
        const currentYear = moment().format("YYYY");

        /**
         * Checkes if the data has any commits that match the label
         */
        const checkMissing = [
            (data:ScCommitGroup[], label)=> {//Monthly
                const hasCommit = [...data].some(commitGroup => label == moment(commitGroup.dateStart).format("MMMM"));
                if(!hasCommit){
                    const monthDate = moment(`1 ${label} ${currentYear}`);
                    return {
                        dateStart: monthDate.format("YYYY-MM-DD HH:mm:ss"),
                        dateEnd: monthDate.endOf("month").format("YYYY-MM-D HH:mm:ssD"),
                        count: 0, commits: []
                    };
                }
                return false;
            },
            (data:ScCommitGroup[], label)=> {//Weekly
                const weekEnd = moment(label).endOf("week").format("YYYY-MM-DD 23:59:59");

                const hasCommit = [...data].some(commitGroup => {
                    return moment(commitGroup.dateStart+" 00:00:05").isBetween(label+" 00:00:00", weekEnd, null, '[]');
                });
                if(!hasCommit){
                    return {
                        dateStart: label,
                        dateEnd: weekEnd,
                        count: 0, commits: []
                    };
                }
                return false;
            },
            (data:ScCommitGroup[], label)=> {//Today
                const hourEnd = moment(label).endOf("hour").format("YYYY-MM-DD HH:mm:ss");

                const hasCommit = [...data].some(commitGroup => {
                    return moment(commitGroup.dateStart).isBetween(label, hourEnd, null, '[]');
                });
                if(!hasCommit){
                    return {
                        dateStart: label,
                        dateEnd: hourEnd,
                        count: 0, commits: []
                    };
                }
                return false;
            },
            (data:ScCommitGroup[], label)=> {//Daily
                const dayEnd = moment(label).endOf("day").format("YYYY-MM-DD 23:59:59");
                const hasCommit = [...data].some(commitGroup => {
                    return moment(commitGroup.dateStart).isBetween(label+" 00:00:00", dayEnd, null, '[]');
                });
                if(!hasCommit){
                    return {
                        dateStart: label,
                        dateEnd: dayEnd,
                        count: 0, commits: []
                    };
                }
                return false;
            },
        ][timeFrequency];
        labels.map(label => {

            const redMissing:any = checkMissing(redData, label);
            const blueMissing:any = checkMissing(blueData, label);
            const userMissing:any = checkMissing(userData, label);
            redMissing != false && redData.push(redMissing);
            blueMissing != false  && blueData.push(blueMissing);
            userMissing != false  && userData.push(userMissing);

        });
        return {redFilled:redData, blueFilled:blueData, userFilled: userData}
    }

    public sortCommitData(data:ScCommitGroup[]){
        return data.sort((a:ScCommitGroup, b:ScCommitGroup) => new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime());
    }

    public cleanLabels(timeFrequency: TimeFrequency, labels:string[]){
        const clean = [
            (labels:string[])=> {//Monthly
               return labels;
            },
            (labels:string[])=> {//Weekly
                return labels.map(label => Util.getWeeksAgo(label));
            },
            (labels:string[])=> {//Today
                return labels.map(label => moment(label).format("H A"));
            },
            (labels:string[])=> {//Daily
                return labels.map(label => Util.getDaysAgo(label));
            },
        ][timeFrequency];
        return clean(labels);
    }

    public generateDataForLineChart(){
        const widgetContext = this.props.context;
        const { timeFrequency } = widgetContext;
        const { redCommits, blueCommits, userCommits } = this.state;

        const user = widgetContext.data.user;
        const teamsMode = !user;
        //Generate labels
        const labels = this.getChartLabels(timeFrequency);

        //Filter by the labels
        const {redFiltered, blueFiltered, userFiltered} = this.getFilteredDatas(timeFrequency, labels, [...redCommits], [...blueCommits], [...userCommits]);

        //Add blank data for any missing logs
        const {redFilled, blueFilled, userFilled} = this.fillBlankData(timeFrequency, labels, [...redFiltered], [...blueFiltered], [...userFiltered]);

        //Sorted latest to newest
        const redSorted = this.sortCommitData([...redFilled]);
        const blueSorted = this.sortCommitData([...blueFilled]);
        const userSorted = this.sortCommitData([...userFilled]);

        //Clean labels
        const cleanLabels = this.cleanLabels(timeFrequency, [...labels]);

        let sets = [

        ];
        if(teamsMode){
            sets.push( {
                data: redSorted.map(commitGroup => commitGroup.count),
                label: 'Red Team',
                borderColor: '#E52121',
                borderWidth: '2',
                pointHoverRadius: 10,
                pointBorderWidth: 2,
                backgroundColor: '#E52121',
                pointBackgroundColor: '#E52121'
            })
            sets.push({
                data: blueSorted.map(commitGroup => commitGroup.count),
                label: 'Blue Team',
                borderColor: '#2C5EAA',
                borderWidth: '2',
                backgroundColor: '#2C5EAA',
                pointHoverRadius: 10,
                pointBorderWidth: 2,
                pointBackgroundColor: '#2C5EAA'
              })
        }else{
            sets.push( {
                data: userSorted.map(commitGroup => commitGroup.count),
                label: widgetContext.data.user.firstName,
                borderColor: '#1A202D',
                borderWidth: '2',
                pointHoverRadius: 10,
                pointBorderWidth: 2,
                backgroundColor: '#1A202D',
                pointBackgroundColor: '#1A202D'
            })
        }

        return Util.generateLineChartData(cleanLabels, sets);
    }

}

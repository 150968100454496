import { WidgetStyles } from "~/app/components/core/widget/Widget";
import { ScWidget } from "~/app/services/Types";
import BaseWidget, { BaseWidgetState, ScWidgetContext } from "../BaseWidget";
import UserBanner from "./components/UserBanner";

interface IProps extends ScWidget{

}


interface IState extends BaseWidgetState{

    
}

export default class UserBannerWidget extends BaseWidget<IProps, IState> {
    
    constructor(props:IProps){
        super(props);

        this.state = {
            loading:true,
            
        }
    }

    public info(){
        return   {
            title: "____", 
            className: "", 
            defaultStyle: WidgetStyles.Normal,
            topLayout: "inline"
        }
    }

    public async onUpdated(context: ScWidgetContext): Promise<void> {
        await this.loadAsync(context);
    }

    public async onPostLoad(context: ScWidgetContext) {
        await this.loadAsync(context);
    }

    private async loadAsync(context: ScWidgetContext) {
        this.setState({loading:false});
    }

    public render()
    {
        const widgetContext = this.props.context;
        const {slug} = this.props;
        const {size, data} = widgetContext;
        const {user} = data;
        
        return (
            <UserBanner 
                    key={slug}
                    data-size={size}  
                    user={user}
            />
        );
    }

}
import { ScUser } from '~/app/services/Types';
import './UserBanner.scss';

interface IProps {
  user:ScUser;
}

export default function UserBanner(props: IProps) {
  const {user} = props;
  const image = user ? user.profile_image : null;

  if(!user)return null;

  return (
    <div key={user.email} className={['userBanner', user.gender, user.team].join(" ")}>
      <div 
        className={['bannerUserImage', user.gender, (!image ? 'initials' : null) ].join(" ")} 
        style={{ backgroundImage: `url(${image})` }}>
          {!image ? user.firstName[0].toUpperCase() : null}
      </div>
      <div className='cardUserContent'>
          <div className='cardUserName'>{user.firstName + " "+ user.lastName}</div>
          <div className='cardUserPosition'>{user.position}</div>
      </div>
      <div className='bannerUserTimes'>
          <div className='bannerUserLastEntry'>Last time entry 4 hours ago</div> 
          <div className='cardUserTeam'>{user.team ? user.team : "Red"} Team</div> 
      </div>
    </div>
  );
}

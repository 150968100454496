import { createRef, useRef } from 'react';
import { useEffect, useState } from 'react';
import './ToggleGroup.scss';

interface IToggleGroupProps {
  options: any;
  defaultValue?: any;
  onOptionChange: any;
  style?: IToggleStyle;
  color?: string;
  width?: number;
  gap?: number;
}

export enum IToggleStyle {
  Pills,
  Tabs
}


export default function ToggleGroup(props: IToggleGroupProps) {
  let { style, color, width, gap } = props;
  style = style ? style : IToggleStyle.Pills;
  color = color ? color : "#F6F6F5";
  width = width ? width : 100;
  gap = gap ? gap : 5;
  const options = props.options;
  const optionKeys = Object.keys(options);
  const defaultValue = props.defaultValue;
  let [selectedKey, setSelectedKey] = useState(defaultValue);
  let [hoveringKey, setHoveringKey] = useState('');
  let [hoverDotX, setHoverDotX] = useState(-1);
  let [bounce, setBounce] = useState(false);
  const indexOfHoveringKey = optionKeys.indexOf(hoveringKey.toString());
  const indexOfActiveKey = optionKeys.indexOf(selectedKey.toString());
  const hideHoverItem = indexOfHoveringKey === -1 && indexOfActiveKey === -1;
  const ITEM_WIDTH = width;
  const IGNORE_WIDTH = width === -1;
  const ADDITIONAL_WIDTH = style === IToggleStyle.Pills ? 10 : 0;
  const itemsRef = useRef({});


  const elementOfHoveringKey = itemsRef.current[indexOfHoveringKey];
  const elementOfActiveKey = itemsRef.current[indexOfActiveKey];

  let Options = optionKeys.map((key: string) => {
    const isHovering = hoveringKey === key;
    const isActive = selectedKey === key;
    const isActiveAndNoHover = isActive && hoveringKey === '';
    return (
      <div
        ref={el => itemsRef.current[key] = el}
        className={'toggleGroupOption ' + (isHovering ? ' is-hover ' : '') + (isActiveAndNoHover ? '  is-active ' : '')}
        key={key}
        style={!IGNORE_WIDTH ? { width: ITEM_WIDTH}: {}}
        onMouseEnter={() => setHoveringKey(key)}
        onMouseLeave={() => {
          if (isHovering) setHoveringKey('');
        }}
        onClick={() => setSelectedKey(key)}
      >
        {options[key]}
      </div>
    );
  });


  useEffect(() => {
    if (indexOfActiveKey === -1) return;
    let x = getX(indexOfActiveKey);
    setHoverDotX(x);
  }, []);

  useEffect(() => {
    if (indexOfActiveKey === -1) return;

    let x = getX(indexOfActiveKey);
    setHoverDotX(x);

    setBounce(true);

    const optionText = options[selectedKey];
    props.onOptionChange(selectedKey, optionText);
  }, [selectedKey]);

  useEffect(() => {
    if (hoveringKey === '') {
      let x = getX(indexOfActiveKey);
      setHoverDotX(x);
      return;
    }

    let x = getX(indexOfHoveringKey);

    setHoverDotX(x);
  }, [hoveringKey]);

  const getX = (key) => {
    if(IGNORE_WIDTH){
      const realKey = Object.keys(itemsRef.current)[key];
      const element = itemsRef.current[realKey];

      return element ? element.offsetLeft-ADDITIONAL_WIDTH : 0;
    }else{
      const width = ITEM_WIDTH;
      return key * width + key * gap;
    }

  }

  const getWidth = () => {
    if(IGNORE_WIDTH){
      const key = hoveringKey === '' ? indexOfActiveKey : indexOfHoveringKey;
      const realKey = Object.keys(itemsRef.current)[key];

      const element = itemsRef.current[realKey];
      return element ? element.offsetWidth+ADDITIONAL_WIDTH*2 : 0;

    }else{
      return ITEM_WIDTH;
    }
  }



  const styles:any = {
    "--toggle-group-color": color,
    gap: gap
  }

  return (
    <div className="toggleGroup" toggle-style={IToggleStyle[style]} style={styles}>
      <div
        className={'toggleGroupHoverItem ' + (bounce ? ' bounce ' : '')}
        style={
          hideHoverItem
            ? {
                transform: `translateX(${hoverDotX}px) scale(0) `,
                width: getWidth()
              }
            : {
                transform: `translateX(${hoverDotX}px) scale(1) `,
                width: getWidth()
              }
        }
      >
        <div className={'toggleGroupHoverItemInner'} onAnimationEnd={() => setBounce(false)}></div>
      </div>
      {Options}
    </div>
  );
}

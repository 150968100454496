import React from "react";

export interface ScoutClassComponentState{
    loading: boolean;
}

export default abstract class ScoutClassComponent<T, J extends ScoutClassComponentState> extends React.Component<T, J> {

    protected dontUpdate:boolean = false;

    constructor(props:T){
        super(props);
        this.onInit();
    }

    public onInit(){}

    public async onPreLoad(){}

    public async onPostLoad(){}

    public async onRefresh(){}

    public onRender(){}

    public async onUpdated(){}

    public async onDispose(){}

    public onRenderTop(){}

    public getProps():T {
        return this.props;
    }
    
    public componentDidMount(): void {
        this.onPostLoad();
    }

    public componentDidUpdate(prevProps: Readonly<T>, prevState: Readonly<J>, snapshot?: any): void {
        if(this.dontUpdate){
            this.dontUpdate = false;
            return;
        }
        this.onUpdated();
    }

    public componentWillUnmount(): void {
        this.onDispose();
    }


    public refresh(){
       
        this.forceUpdate(()=> {
            this.onRefresh();
        });
    }

    public setState(newState:any, callback?:any, forceUpdate=false){
        if(!forceUpdate)this.dontUpdate = true;
        super.setState(newState, callback);
    }

}

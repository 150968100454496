import { ReactComponent as IconMonitor } from '~/assets/icons/monitor-ico.svg';
import { ReactComponent as IconBack } from '~/assets/icons/arrowleft-ico.svg';
import { ReactComponent as IconSave } from '~/assets/icons/check-ico.svg';
import { ReactComponent as IconDelete } from '~/assets/icons/delete-ico.svg';
import { BbUser, ScProject, ScPage, ScUser, TimeFrequency, ScClient } from '~/app/services/Types';
import BasePage, { BasePageState } from '../../BasePage';
import { getUserBy, updateUser } from '~/app/services/UserService';
import { getEHUsers, getSTUsers } from '~/app/services/StreamTimeService';
import { FieldType, Grid, SettingField, Widget, WidgetStyles } from '~/app/components/core';
import Button, { ButtonStyle } from '~/app/components/inputs/button/Button';
import { notify, notifyError, notifySuccess } from '~/app/services/ToastService';
import { urlQuery } from '~/app/services/API';
import {
  BitBucketProjects,
  ScoutClientsPicker,
  StreamTimeClients, StreamTimeProjects,
} from '~/app/components/core/settingField/SettingField';
import { findClientBy, updateClient } from '~/app/services/ClientService';
import { getProject, updateProject } from '~/app/services/ProjectService';

interface IProps extends ScPage {}

interface IState extends BasePageState {
  project: ScProject;
  projectClient: ScClient;
  updatedProject: any;
}

export default class PageProjectEdit extends BasePage<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: true,
      project: null,
      projectClient: null,
      updatedProject: {},
      timeFrequency: TimeFrequency.Monthly
    };
  }

  public info(): any {
    const pageContext = this.props.context;
    const { appContext } = pageContext;

    return {
      title: 'Edit Project',

      icon: <IconMonitor />,
      className: '',
      showTop: false,
      renderCustom: true,
      columns: 2
    };
  }

  public async onPostLoad() {
    const pageContext = this.props.context;
    const { appContext } = pageContext;
    let searchParams = appContext.searchParams;
    if (!searchParams || !searchParams.get('id')) {
      searchParams = urlQuery();
    }

    let projectId = searchParams.get('id');

    if (projectId) {
      const project = await getProject(projectId);
      const projectClient = await findClientBy("clientId", project.clientId);
      this.setState({
        loading: false,
        project: project,
        projectClient: projectClient,
      });
    } else {
      //Not found
      notifyError(`Project not found '${projectId}'`, appContext.searchParams);
    }
  }

  public render(): React.ReactElement<IProps> {
    const pageContext = this.props.context;


    const { slug } = this.props;
    const { icon, title, showTop, renderCustom } = this.info();
    const { loading, project } = this.state;

    return (
      <div className="page" data-page={slug}>
        <main>
          <div className="topArea">
            <h3>
              {icon} {title}
            </h3>
            {this.onRenderTop()}
          </div>
          <Grid gap={20} columns={1} key={`${project && project.projectId}_${loading}`}>
            <Widget
              key={'projectGeneral'}
              data-size={6}
              widgetStyle={WidgetStyles.Settings}
              title={'General'}
              className={''}
              topLayout={''}
              top={null}
              fit={false}
              loading={loading}
            >
              {project && this.getGeneral(project)}
            </Widget>

            <Widget
              key={'projectLinks'}
              data-size={6}
              widgetStyle={WidgetStyles.Settings}
              title={'Integrations'}
              className={''}
              topLayout={''}
              top={null}
              fit={false}
              loading={loading}
            >
              {project && this.getIntegrations(project)}
            </Widget>
          </Grid>
        </main>
      </div>
    );
  }

  public onRenderTop() {
    return (
      <div style={{ gap: 20, display: 'flex', flexWrap: 'wrap', marginRight: '20px' }}>
        <Button
          text="Go back"
          style={ButtonStyle.normal}
          to={'/config'}
          icon={<IconBack />}
          onClick={() => {
            notify('Project changes discarded.');
          }}
        />
        <Button
          text="Save Project"
          style={ButtonStyle.primary}
          icon={<IconSave />}
          onClick={() => {
            this.save();
          }}
        />
        <Button
          text="Delete Project"
          style={ButtonStyle.danger}
          icon={<IconDelete />}
          onClick={() => {
            notifyError('Project deleted.');
          }}
        />
      </div>
    );
  }

  public save() {
    const { updatedProject, project } = this.state;
    if (!project) return;
    updatedProject['projectId'] = project.projectId;
    updateProject(project.projectId, updatedProject);
    notifySuccess('Project saved!');
    this.setState({ updatedProject: {} });
  }

  public getGeneral(project: ScProject): any {
    const pageContext = this.props.context;


    return (
      <>
        <SettingField
          slug={'Id'}
          title={'ID'}
          readOnly
          value={project.projectId}
          type={FieldType.Text}
        />
        <SettingField
          slug={'name'}
          title={'Project Name'}
          value={project.name}
          type={FieldType.Text}
          onChange={(slug: string, ref: any, oldVal: any, newVal: any) => {
            this.onFieldChange(slug, newVal);
          }}
        />
        <ScoutClientsPicker
             slug={"clientId"}
             value={project.clientId}
             onChange={ (slug:string, newVal:any) => {
              this.onFieldChange(slug, newVal);
              findClientBy("clientId", newVal).then(projectClient => {
                this.setState({
                  projectClient: projectClient,
                });
              })

             }}
        />
        <SettingField
          slug={'startDate'}
          title={'Start date'}
          value={project.startDate}
          type={FieldType.Date}
          onChange={(slug: string, ref: any, oldVal: any, newVal: any) => {
            this.onFieldChange(slug, newVal);
          }}
        />
        <SettingField
          slug={'endDate'}
          title={'End date'}
          value={project.endDate}
          type={FieldType.Date}
          onChange={(slug: string, ref: any, oldVal: any, newVal: any) => {
            this.onFieldChange(slug, newVal);
          }}
        />
        <SettingField
                slug={"budget"}
                title={"Budget"}
                value={project.budget}
                type={FieldType.Number}
                prefix={"$"}
                suffix={".00"}
                onChange={ (slug:string, ref:any, oldVal:any, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />




      </>
    );
  }

  public getIntegrations(project: ScProject): any {
    const pageContext = this.props.context;
    const { page, appContext } = pageContext;
    const { projectClient } = this.state;

    return (
      <>
        <StreamTimeProjects
             slug={"streamtime_id"}
             value={project.streamTimeId}
             onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
            />
            {projectClient && projectClient.bitbucket_id ? (
              <BitBucketProjects
                slug={"bitbucket_project_id"}
                workspaceSlug={projectClient.bitbucket_id}
                value={project.bitbucket_project_id}
                onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
                />
            ):
            (
              <div><br/>* To connect to Bitbucket setup in workspace in the client</div>
            )
            }
               {/* <UsersPicker
                  slug={"users"}
                  value={project.users}
                  onChange={ (slug:string, newVal:any) => {this.onFieldChange(slug, newVal)}}
              /> */}
      </>
    );
  }

  public onFieldChange(fieldSlug: string, newValue: string): any {
    let { updatedProject, project } = this.state;
    const oldVal = project[fieldSlug];
    if (oldVal !== newValue) {
      updatedProject[fieldSlug] = newValue;
      project[fieldSlug] = newValue;
      this.setState({ project: project, updatedupdatedProjectUser: updatedProject });
    }
  }
}

/**
 * Scout Nav Service
 */
 import { ReactComponent as IconFinance } from "~/assets/icons/billing-ico.svg";
 import { ReactComponent as IconOverview } from "~/assets/icons/browseall-ico.svg";
 import { ReactComponent as IconPerformance } from "~/assets/icons/monitor-ico.svg";
import { ScNavItem} from "./Types";
import { NavLink } from 'react-router-dom';

/** API */

export const getNavItems = async (): Promise<ScNavItem[]> => {
    const navItems:ScNavItem[] = [
        {
          name: "Overview",
          link: "/overview",
          icon: <IconOverview />,
        },
        {
          name: "Performance",
          link: "/projects",
          icon: <IconPerformance />,
        },
        {
          name: "Finance",
          link: "/finance",
          icon: <IconFinance />,
        }
    ];
    return navItems;
}


/** Util */
export const renderNavItems = (navItems:ScNavItem[], hoveringIndex:number, activeIndex:number, onHover:(navItem:ScNavItem, index:number)=>void, onHoverLeave:()=>void, onActive:(navItem:ScNavItem, index:number)=>void) => {
  let navIconsEls:any = [];
  let navItemEls:any = [];

  navItems.map((navItem:ScNavItem, index:number) => {
    let isHovering = hoveringIndex == index;
    let isActive = (activeIndex == index) && hoveringIndex == -1;
    navIconsEls.push(renderNavItemIcon(navItem, isHovering, isActive, () => {
      onHover(navItem, index);
    }, () => {
      if(isHovering) onHoverLeave();
    }));

    navItemEls.push(renderNavItemLink(navItem, isHovering, isActive, () => {
      onHover(navItem, index);
    }, () => {
      if(isHovering) onHoverLeave();
    }, () => {
      onActive(navItem, index);
    }));


  })

  return {navIconsEls, navItemEls};
}


export const renderNavItemIcon = (navItem:ScNavItem, isHovering:boolean, isActive:boolean, onHover:()=>void, onHoverLeave:()=>void) => {
  return (
    <div
        key={"niIcon_"+navItem.name}
        className={`nav-icon ${isHovering ? 'is-hover' : ''} ${isActive ? ( 'is-active') : ''}`}
        onMouseEnter={() => onHover()} onMouseLeave={() => {onHoverLeave()}} >
        <NavLink
          title={navItem.name}
          to={navItem.link} >
          {navItem.icon}
        </NavLink>
    </div>
  );
}


export const renderNavItemLink = (navItem:ScNavItem, isHovering:boolean, isActived:boolean, onHover:()=>void, onHoverLeave:()=>void, onActive:()=>void) => {
  return (
    <div
      key={"niLink_"+navItem.name}
      onMouseEnter={() => onHover()}
      onMouseLeave={() => {onHoverLeave()}}>
      <NavLink
        to={navItem.link}
        className={({ isActive }) =>{
          if(isActive)onActive();
          return `nav-link ${isHovering ? 'is-hover' : ''} ${isActived ? ( 'is-active') : ''}`
        } }>
        <span>{navItem.name}</span>
      </NavLink>
    </div>
  );
}
